import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Tabela from "../../../components/Tabela/TabelaViewBoletos";
import Menu from "../../../components/Menu/Menu";
import Navbar from "../../../components/Navbar/Navbar";
import moment from 'moment';
import "react-widgets/styles.css";
import Contexto from "../../../components/Contexto/Contexto";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

export default function Canceladas() {
  const history = useHistory();
  const [qtdmovimentos, setQtdmovimentos] = useState(0);
  const [dadostabela, setDadostabela] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Notas Canceladas</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);

        async function carregaView(){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", "Basic YWxlc3NhbmRyby5nb25jYWx2ZXM6MTI4MDEyMDY=");
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "dataservername": "FinBoletoData",
            "filtro": `(FBOLETO.CODCOLIGADA=${localStorage.getItem("@integrador-app/codcoligada")} AND FBOLETO.CODFILIAL = ${localStorage.getItem("@integrador-app/codfilial")} AND FBOLETO.DATAEMISSAO BETWEEN '${localStorage.getItem("@integrador-app/dtini")}' AND '${localStorage.getItem("@integrador-app/dtfim")}')`,
            "contexto": `CODCOLIGADA=${localStorage.getItem("@integrador-app/codcoligada")};CODSISTEMA=F;CODUSUARIO=${localStorage.getItem("@integrador-app/codusuario")}`
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          const rsponse = await fetch("https://www.suporteinspira.com.br:8081/dataserver/readview", requestOptions)
            .then(response => response.json())
            .then(result => {
              if (result !== ""){
                console.log(result.FBOLETO);
                setDadostabela(result.FBOLETO);
                setQtdmovimentos(result.record_count)
                localStorage.setItem('@integrador-app/qtdmov', result.record_count);
                setIsloadtabela(false);
              } else {
                console.log('Não retornou nada');
                setQtdmovimentos(0);
                setDadostabela([]);
                localStorage.setItem('@integrador-app/qtdmov', result.record_count);
                setIsloadtabela(false);
              }

              
            })
            .catch(error => console.log('error', error));
           
        }

        carregaView();

        // Carrego o Movimento
        /*const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "status": "C",
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };
        fetch(`${api}/notasfiscaisporstatus`, requestOptions)
        .then(response => response.json())
        .then(
          result => { 
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              setDadostabela([]);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count)
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
        });
        */
      }
    }
  }, []);

  function CarregarMovimento() {
    const coligada = localStorage.getItem("@integrador-app/codcoligada");
    if (coligada === null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Notas Canceladas</span>',
        html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    } else {
      setIsloadtabela(true);
      // Carrego o Movimento
      const params = {
        "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
        "codfilial": localStorage.getItem("@integrador-app/codfilial"),
        "status": "C",
        "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
        "dataini": localStorage.getItem("@integrador-app/dtini"),
        "datafim": localStorage.getItem("@integrador-app/dtfim")
      }
      console.log(params);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };
      fetch(`${api}/notasfiscaisporstatus`, requestOptions)
      .then(response => response.json())
      .then(
        result => { 
          if (result.status_code == "400") {
            setQtdmovimentos(0);
            setDadostabela([]);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          } else {
            setDadostabela(result.dados);
            setQtdmovimentos(result.record_count);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          }
      });
    }
  }

  return (
    <div id="wrapper">
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <Contexto />
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Notas Fiscais Canceladas { qtdmovimentos ? ' - Total: '+qtdmovimentos : '' }
                  <div class="card-action">
                    <div class="dropdown">
                      <a
                        href="javascript:void();"
                        class="dropdown-toggle dropdown-toggle-nocaret"
                        data-toggle="dropdown"
                      >
                        <i class="icon-options"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void();" onClick={CarregarMovimento}>
                          Recarregar Notas
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabela dados={dadostabela} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
