import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import './load.css';
import inspiralogo from "../../assets/images/global/logo-inspira-vazado.png";

const Load = () => {
    return (  
        <div className="page-load">
            <img src={inspiralogo} alt="logo" width="250px" />
            <div className="load-page-spinner">
                <PulseLoader color={'#fff'} loading={true} size={5} />
            </div>
        </div>
    )
}

export default Load;