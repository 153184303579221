import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import PulseLoader from "react-spinners/PulseLoader";
import Swal from "sweetalert2";
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import XMLParser from "react-xml-parser";
import Switch from "../Switch/SwitchButton";
import "react-data-table-component-extensions/dist/index.css";

import "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { FormatAlignJustifyTwoTone } from "@material-ui/icons";

import verificado from "../../assets/images/verificado.png";
import pedente from "../../assets/images/warn.png";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const initialvalue = {
  arquivo: "",
  senha: "",
  empresaId: "",
};

export default function Tabela(props) {
  const classes = useStyles();
  const [carctpref, setCarctpref] = useState([]);
  const [isload, setIsload] = useState(null);
  const [values, setValues] = useState(initialvalue);
  const [nomefilial, setNomefilial] = useState("");
  const [idfilial, setIdfilial] = useState("");
  const [arquivo, setArquivo] = useState([]);
  const [senha, setSenha] = useState("");
  const [modalcertificado, setModalcertificado] = useState(false);
  const abrircertificado = () => setModalcertificado(!modalcertificado);
  const [modalcertificadonfe, setModalcertificadonfe] = useState(false);
  const abrircertificadoNfe = () => setModalcertificadonfe(!modalcertificadonfe);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  useEffect(() => {
    // function handleValidacaoClicked(id){
    //   alert('clicked!');
    // }
  }, []);

  function integarFilialNfe(
    idcoligada,
    idfilial,
    filial,
    integrada,
    idempresa
  ) {
    Swal.fire({
      title: "Atenção",
      html: '<p style="font-size: 16px;">Você realmente deseja integrar a filial para envio de NFe?</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, desejo!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsload(true);
        async function postAtualizaFilial() {
          var myHeaders = new Headers();
          myHeaders.append(
            "Cookie",
            "ci_session=a76a01otvqror8cpggjm37ir4pcr944k"
          );

          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          fetch(
            `${api}/getfiliais?codcoligada=${idcoligada}&codfilial=${idfilial}`,
            requestOptions
          )
            .then((response) => {
              setIsload(false);
              if (!response.ok) throw new Error(response.status);
              else return response.json();
            })
            .then((json) => {
              if (json.status_code === 200) {
                console.log(json.dados);

                var filialHeaders = new Headers();
                filialHeaders.append("Accept", "application/json");
                filialHeaders.append(
                  "Authorization",
                  "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
                );
                filialHeaders.append("Content-Type", "application/json");

                const dadosFilial = {
                  cnpj: json.dados[0].PrestadorCnpj,
                  inscricaoEstadual: json.dados[0].PrestadorInscricaoEstadual,
                  inscricaoMunicipal: null,
                  razaoSocial: json.dados[0].NOME,
                  nomeFantasia: json.dados[0].NOMEFANTASIA,
                  optanteSimplesNacional: false,
                  email: json.dados[0].EMAIL,
                  telefoneComercial: json.dados[0].TELEFONE,
                  endereco: {
                    uf: json.dados[0].ESTADO,
                    cidade: json.dados[0].NOMEMUNICIPIO,
                    logradouro: json.dados[0].RUA,
                    numero: json.dados[0].NUMERO,
                    complemento: json.dados[0].COMPLEMENTO,
                    bairro: json.dados[0].BAIRRO,
                    cep: json.dados[0].PrestadorEnderecoCEP,
                  },
                  emissaoNFeProduto: {
                    ambienteProducao: {
                      sequencialNFe: 1,
                      serieNFe: "1",
                      sequencialLoteNFe: 1,
                    },
                    ambienteHomologacao: {
                      sequencialNFe: 1,
                      serieNFe: "1",
                      sequencialLoteNFe: 1,
                    },
                  },
                };

                var raw = JSON.stringify(dadosFilial);
                console.log(dadosFilial);

                var requestOptions = {
                  method: "POST",
                  headers: filialHeaders,
                  body: raw,
                  redirect: "follow",
                };

                fetch("https://api.enotasgw.com.br/v2/empresas", requestOptions)
                  .then((response) => response.json())
                  .then((result) => {
                    setIsload(false);
                    console.log(result.empresaId);

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append(
                      "Cookie",
                      "ci_session=nra4vt8tj046ht4k6mf7ut3edp05731h"
                    );

                    const dadosFilialIntegrada = {
                      codcoligada: idcoligada,
                      codfilial: idfilial,
                      idempresa: result.empresaId,
                      usuario: localStorage.getItem(
                        "@integrador-app/codusuario"
                      ),
                    };

                    var raw = JSON.stringify(dadosFilialIntegrada);

                    var requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: raw,
                      redirect: "follow",
                    };

                    setIsload(true);
                    fetch(`${api}/intergrafilial`, requestOptions)
                      .then((response) => {
                        setIsload(false);
                        if (!response.ok) throw new Error(response.status);
                        else return response.json();
                      })
                      .then((json) => {
                        Swal.fire({
                          title:
                            '<p style="font-size: 22px;">Integração da Filial</p>',
                          html: '<p style="font-size: 16px;">A Filial foi integrada com sucesso ao sistema, agora já é possível o envio de Notas fiscais dessa Filial pelo sistema (Integrador Fiscal).</p>',
                          icon: "success",
                          confirmButtonText: "Sair",
                        });
                      })
                      .catch((error) => {
                        setIsload(false);
                        console.log("error", error);
                        Swal.fire({
                          title:
                            '<p style="font-size: 22px;">Integração da Filial</p>',
                          html: '<p style="font-size: 16px;">Não foi possível salvar a integração dessa filial, favor irnformar o time de sistemas.</p>',
                          icon: "error",
                          confirmButtonText: "Sair",
                        });
                      });

                    Swal.fire({
                      title:
                        '<p style="font-size: 22px;">Integração da Filial</p>',
                      html: '<p style="font-size: 16px;">Os dados desta filial foram integrados com sucesso.</p>',
                      icon: "success",
                      confirmButtonText: "Sair",
                    });
                  })
                  .catch((error) => {
                    console.log("error", error);
                    setIsload(false);
                    Swal.fire({
                      title:
                        '<p style="font-size: 22px;">Integração da Filial</p>',
                      html: '<p style="font-size: 16px;">Não foi possível salvar a integração desta filial no eNotas, favor irnformar o time de sistemas.</p>',
                      icon: "error",
                      confirmButtonText: "Sair",
                    });
                  });
              }
            })
            .catch((error) => {
              setIsload(false);
              console.log("error", error);
            });
        }
        postAtualizaFilial();
      }
    });
  }

  function onSubmitCerificado(e) {
    e.preventDefault();
    setIsload(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var formdata = new FormData();
    formdata.append("arquivo", arquivo, arquivo.name);
    formdata.append("senha", senha);
    formdata.append("empresaId", idfilial);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${idfilial}/certificadoDigital`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((result) => {
        //console.log(result);
        var xml = new XMLParser().parseFromString(result); // Assume xmlText contains the example XML
        // console.log(xml);
        console.log(xml.getElementsByTagName("dataVencimento")[0].value);
        console.log(
          moment(
            xml.getElementsByTagName("dataVencimento")[0].value.toString()
          ).format("YYYY-MM-DD")
        );
        setIsload(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Cookie",
          "ci_session=dmup444ku8aev3g7b7r8t06q92vf3ked"
        );

        var raw = JSON.stringify({
          empresaId: idfilial,
          vencto: xml.getElementsByTagName("dataVencimento")[0].value,
          // "vencto": moment(xml.getElementsByTagName('dataVencimento')[0].value).format("YYYY-DD-MM")
        });

        // console.log(raw);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${api}/certificadofilial`, requestOptions)
          .then((response) => {
            setIsload(false);
            response.text();
          })
          .then((result) => {
            console.log(result);
            Swal.fire({
              title:
                '<span style="font-size: 22px;">Certificado Digital</span>',
              html: '<p style="font-size: 16px;">Certificado importado com sucesso.</p>',
              icon: "success",
              confirmButtonText: "Sair",
            });
          })
          .catch((error) => {
            console.log("error", error);
            Swal.fire({
              title:
                '<span style="font-size: 22px;">Certificado Digital</span>',
              html: '<p style="font-size: 16px;">Erro ao tentar salvar os dados de vencimento do certificado.</p>',
              icon: "success",
              confirmButtonText: "Sair",
            });
          });
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: '<span style="font-size: 22px;">Certificado Digital</span>',
          html: '<p style="font-size: 16px;">Senha do certificado não confere</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      });
  }

  function enviarCertificado(filial, idempresa) {
    setNomefilial(filial);
    setIdfilial(idempresa);
    abrircertificado();
  }

  function onSubmitCerificadoNfe(e) {
    e.preventDefault();
    setIsload(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var formdata = new FormData();
    formdata.append("arquivo", arquivo, arquivo.name);
    formdata.append("senha", senha);
    formdata.append("empresaId", idfilial);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v2/empresas/${idfilial}/certificadoDigital`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.text();
      })
      .then((result) => {
        //console.log(result);
        var xml = new XMLParser().parseFromString(result); // Assume xmlText contains the example XML
        // console.log(xml);
        console.log(xml.getElementsByTagName("dataVencimento")[0].value);
        console.log(
          moment(
            xml.getElementsByTagName("dataVencimento")[0].value.toString()
          ).format("YYYY-MM-DD")
        );
        setIsload(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Cookie",
          "ci_session=dmup444ku8aev3g7b7r8t06q92vf3ked"
        );

        var raw = JSON.stringify({
          empresaId: idfilial,
          vencto: xml.getElementsByTagName("dataVencimento")[0].value,
          // "vencto": moment(xml.getElementsByTagName('dataVencimento')[0].value).format("YYYY-DD-MM")
        });

        // console.log(raw);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${api}/certificadofilial`, requestOptions)
          .then((response) => {
            setIsload(false);
            response.text();
          })
          .then((result) => {
            console.log(result);
            Swal.fire({
              title:
                '<span style="font-size: 22px;">Certificado Digital</span>',
              html: '<p style="font-size: 16px;">Certificado importado com sucesso.</p>',
              icon: "success",
              confirmButtonText: "Sair",
            });
          })
          .catch((error) => {
            console.log("error", error);
            Swal.fire({
              title:
                '<span style="font-size: 22px;">Certificado Digital</span>',
              html: '<p style="font-size: 16px;">Erro ao tentar salvar os dados de vencimento do certificado.</p>',
              icon: "success",
              confirmButtonText: "Sair",
            });
          });
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: '<span style="font-size: 22px;">Certificado Digital</span>',
          html: '<p style="font-size: 16px;">Senha do certificado não confere</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      });
  }

  function enviarCertificadoNfe(filial, idempresa) {
    setNomefilial(filial);
    setIdfilial(idempresa);
    abrircertificadoNfe();
  }

  function handleValidacaoClicked(
    id,
    prefeitura,
    idcoligada,
    idfilial,
    filial,
    integrada,
    idempresa
  ) {
    if (integrada === "true") {
      Swal.fire({
        title: "Atenção",
        html: '<p style="font-size: 16px;">Esta filial já está integrada ao sistema deseja atualizar os dados dela?</p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo!",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsload(true);
          async function postAtualizaFilial() {
            fetch(
              `https://api.enotasgw.com.br/v1/estados/cidades/${id}/provedor`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw",
                },
              }
            )
              .then((response) => {
                setIsload(false);
                if (!response.ok) throw new Error(response.status);
                else return response.json();
              })
              .then((json) => {
                setIsload(true);
                setCarctpref(json);
                var myHeaders = new Headers();
                myHeaders.append(
                  "Cookie",
                  "ci_session=a76a01otvqror8cpggjm37ir4pcr944k"
                );

                var requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };

                fetch(
                  `${api}/getfiliais?codcoligada=${idcoligada}&codfilial=${idfilial}`,
                  requestOptions
                )
                  .then((response) => {
                    setIsload(false);
                    if (!response.ok) throw new Error(response.status);
                    else return response.json();
                  })
                  .then((json) => {
                    if (json.status_code === 200) {
                      var myHeaders = new Headers();
                      myHeaders.append("Accept", "application/json");
                      myHeaders.append("Content-Type", "application/json");
                      myHeaders.append(
                        "Authorization",
                        "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
                      );
                      console.log("COMECE POR AQUI: " + json.dados);
                      const dadosFilial = {
                        id: idempresa,
                        cnpj: json.dados[0].PrestadorCnpj,
                        inscricaoMunicipal:
                          json.dados[0].PrestadorInscricaoMunicipal,
                        inscricaoEstadual: null,
                        razaoSocial: json.dados[0].NOME,
                        nomeFantasia: json.dados[0].NOMEFANTASIA,
                        optanteSimplesNacional: false,
                        regimeEspecialTributacao: "2",
                        email: json.dados[0].EMAIL,
                        telefoneComercial: json.dados[0].TELEFONE,
                        endereco: {
                          pais: json.dados[0].PAIS,
                          uf: json.dados[0].ESTADO,
                          cidade: json.dados[0].NOMEMUNICIPIO,
                          logradouro: json.dados[0].RUA,
                          numero: json.dados[0].NUMERO,
                          complemento: json.dados[0].COMPLEMENTO,
                          bairro: json.dados[0].BAIRRO,
                          cep: json.dados[0].PrestadorEnderecoCEP,
                        },
                        configuracoesNFSeProducao: {
                          sequencialNFe: 1,
                          serieNFe: "NF",
                          sequencialLoteNFe: 1,
                        },
                        configuracoesNFSeHomologacao: {
                          sequencialNFe: 1,
                          serieNFe: "1",
                          sequencialLoteNFe: 1,
                        },
                        modulos: {
                          emissao: true,
                          consulta: true,
                        },
                        cnae: json.dados[0].CODATIVFED
                         ,
                        aliquotaIss: 3.0,
                        itemListaServicoLC116: "08.01",
                        codigoServicoMunicipal:
                          carctpref.usaCodigoServicoMunicipal ? "08.01" : null,
                        descricaoServico: json.dados[0].DESCATIVIDPRINC,
                      };

                      var raw = JSON.stringify(dadosFilial);

                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };
                      setIsload(true);
                      fetch(
                        "https://api.enotasgw.com.br/v1/empresas",
                        requestOptions
                      )
                        .then((response) => {
                          setIsload(false);
                          if (!response.ok) throw new Error(response.status);
                          else return response.json();
                        })
                        .then((json) => {
                          Swal.fire({
                            title:
                              '<p style="font-size: 22px;">Integração da Filial</p>',
                            html: '<p style="font-size: 16px;">Os dados desta filial foram atualizados com sucesso.</p>',
                            icon: "success",
                            confirmButtonText: "Sair",
                          });
                        })
                        .catch((error) => {
                          setIsload(false);
                          Swal.fire({
                            title:
                              '<p style="font-size: 22px;">Integração da Filial</p>',
                            html: '<p style="font-size: 16px;">Não foi possível salvar a integração desta filial no eNotas, favor irnformar o time de sistemas.</p>',
                            icon: "error",
                            confirmButtonText: "Sair",
                          });
                        });
                    }
                  })
                  .catch((error) => {
                    setIsload(false);
                    console.log("error", error);
                  });
              })
              .catch((error) => {
                setIsload(false);
                Swal.fire({
                  title:
                    '<p style="font-size: 22px;">Prefeitura não homologada</p>',
                  html: '<p style="font-size: 16px;">Esta prefeitura ainda não foi homologada.</p>',
                  icon: "error",
                  confirmButtonText: "Sair",
                });
              });
          }
          postAtualizaFilial();
        }
      });
    } else {
      Swal.fire({
        title: '<p style="font-size: 22px;">Validação</p>',
        html: '<p style="font-size: 16px;">Você deseja verificar se a filial é homologada?</p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo!",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsload(true);
          async function getCaracteristicasPrefeitura() {
            fetch(
              `https://api.enotasgw.com.br/v1/estados/cidades/${id}/provedor`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw",
                },
              }
            )
              .then((response) => {
                setIsload(false);
                if (!response.ok) throw new Error(response.status);
                else return response.json();
              })
              .then((json) => {
                var MsgText = "";
                MsgText =
                  MsgText +
                  `Prefeitura de <strong>${prefeitura}</strong> homologada, deseja realizar a integração para o envio de Notas Fiscais?<br><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;"><strong>Principais Caracteristicas:</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Usa CNAE: <strong>${
                    json.usaCNAE ? "Sim" : "Não"
                  }</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Usa Codigo Servico Municipal: <strong>${
                    json.usaCodigoServicoMunicipal ? "Sim" : "Não"
                  }</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Usa Regime Especial Tributacao: <strong>${
                    json.usaRegimeEspecialTributacao ? "Sim" : "Não"
                  }</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Usa ItemListaServico: <strong>${
                    json.usaItemListaServico ? "Sim" : "Não"
                  }</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Usa RegimeEspecial Tributacao: <strong>${
                    json.usaRegimeEspecialTributacao ? "Sim" : "Não"
                  }</strong><br>`;
                if (json.helpItemListaServico) {
                  MsgText =
                    MsgText +
                    `<p align="left" style="font-size: 16px;">helpItemListaServico: <br><strong>${json.helpItemListaServico}</strong><br>`;
                }
                MsgText =
                  MsgText +
                  `<br><p align="left" style="font-size: 16px;"><strong>Funções disponíveis:</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Envio: <strong>Sim</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Consulta: <strong>Sim</strong><br>`;
                MsgText =
                  MsgText +
                  `<p align="left" style="font-size: 16px;">Cancelamento: <strong>${
                    json.suportaCancelamento ? "Sim" : "Não"
                  }</strong><br>`;
                Swal.fire({
                  title: `<p style="font-size: 22px;">${filial}</p>`,
                  html: MsgText,
                  width: "750px",
                  icon: "success",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonText: "Cancelar",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Sim, desejo!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setIsload(true);
                    var myHeaders = new Headers();
                    myHeaders.append(
                      "Cookie",
                      "ci_session=a76a01otvqror8cpggjm37ir4pcr944k"
                    );

                    var requestOptions = {
                      method: "GET",
                      headers: myHeaders,
                      redirect: "follow",
                    };

                    fetch(
                      `${api}/getfiliais?codcoligada=${idcoligada}&codfilial=${idfilial}`,
                      requestOptions
                    )
                      .then((response) => {
                        setIsload(false);
                        if (!response.ok) throw new Error(response.status);
                        else return response.json();
                      })
                      .then((json) => {
                        if (json.status_code === 200) {
                          var myHeaders = new Headers();
                          myHeaders.append("Accept", "application/json");
                          myHeaders.append("Content-Type", "application/json");
                          myHeaders.append(
                            "Authorization",
                            "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
                          );

                          const dadosFilial = {
                            cnpj: json.dados[0].PrestadorCnpj,
                            inscricaoMunicipal:
                              json.dados[0].PrestadorInscricaoMunicipal,
                            inscricaoEstadual: null,
                            razaoSocial: json.dados[0].NOME,
                            nomeFantasia: json.dados[0].NOMEFANTASIA,
                            optanteSimplesNacional: false,
                            regimeEspecialTributacao: "6",
                            email: json.dados[0].EMAIL,
                            telefoneComercial: json.dados[0].TELEFONE,
                            endereco: {
                              pais: json.dados[0].PAIS,
                              uf: json.dados[0].ESTADO,
                              cidade: json.dados[0].NOMEMUNICIPIO,
                              logradouro: json.dados[0].RUA,
                              numero: json.dados[0].NUMERO,
                              complemento: json.dados[0].COMPLEMENTO,
                              bairro: json.dados[0].BAIRRO,
                              cep: json.dados[0].PrestadorEnderecoCEP,
                            },
                            configuracoesNFSeProducao: {
                              sequencialNFe: 1,
                              serieNFe: "1",
                              sequencialLoteNFe: 1,
                            },
                            configuracoesNFSeHomologacao: {
                              sequencialNFe: 1,
                              serieNFe: "1",
                              sequencialLoteNFe: 1,
                            },
                            modulos: {
                              emissao: true,
                              consulta: true,
                            },
                          };

                          var raw = JSON.stringify(dadosFilial);

                          console.log(raw);

                          var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow",
                          };
                          setIsload(true);
                          fetch(
                            "https://api.enotasgw.com.br/v1/empresas",
                            requestOptions
                          )
                            .then((response) => {
                              setIsload(false);
                              if (!response.ok)
                                throw new Error(response.status);
                              else return response.json();
                            })
                            .then((json) => {
                              var myHeaders = new Headers();
                              myHeaders.append(
                                "Content-Type",
                                "application/json"
                              );
                              myHeaders.append(
                                "Cookie",
                                "ci_session=nra4vt8tj046ht4k6mf7ut3edp05731h"
                              );

                              const dadosFilialIntegrada = {
                                codcoligada: idcoligada,
                                codfilial: idfilial,
                                idempresa: json.empresaId,
                                usuario: localStorage.getItem(
                                  "@integrador-app/codusuario"
                                ),
                              };

                              var raw = JSON.stringify(dadosFilialIntegrada);

                              var requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow",
                              };

                              setIsload(true);
                              fetch(`${api}/intergrafilial`, requestOptions)
                                .then((response) => {
                                  setIsload(false);
                                  if (!response.ok)
                                    throw new Error(response.status);
                                  else return response.json();
                                })
                                .then((json) => {
                                  Swal.fire({
                                    title:
                                      '<p style="font-size: 22px;">Integração da Filial</p>',
                                    html: '<p style="font-size: 16px;">A Filial foi integrada com sucesso ao sistema, agora já é possível o envio de Notas fiscais deats Filial pelo sistema (Integrador Fiscal).</p>',
                                    icon: "success",
                                    confirmButtonText: "Sair",
                                  });
                                })
                                .catch((error) => {
                                  setIsload(false);
                                  console.log("error", error);
                                  Swal.fire({
                                    title:
                                      '<p style="font-size: 22px;">Integração da Filial</p>',
                                    html: '<p style="font-size: 16px;">Não foi possível salvar a integração desta filial, favor irnformar o time de sistemas.</p>',
                                    icon: "error",
                                    confirmButtonText: "Sair",
                                  });
                                });
                            })
                            .catch((error) => {
                              setIsload(false);
                              Swal.fire({
                                title:
                                  '<p style="font-size: 22px;">Integração da Filial</p>',
                                html: '<p style="font-size: 16px;">Não foi possível salvar a integração desta filial no eNotas, favor irnformar o time de sistemas.</p>',
                                icon: "error",
                                confirmButtonText: "Sair",
                              });
                            });
                        }
                      })
                      .catch((error) => {
                        setIsload(false);
                        console.log("error", error);
                      });
                  }
                });
              })
              .catch((error) => {
                setIsload(false);
                Swal.fire({
                  title:
                    '<p style="font-size: 22px;">Prefeitura não homologada</p>',
                  html: '<p style="font-size: 16px;">Esta prefeitura ainda não foi homologada.</p>',
                  icon: "error",
                  confirmButtonText: "Sair",
                });
              });
          }
          getCaracteristicasPrefeitura();
        }
      });
    }
  }

  const LoaderComponent = () => (
    <div
      style={{
        height: "150px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <PulseLoader color={"#1565C0"} loading={true} size={7} />
    </div>
  );

  const data = props.dados;

  const columns = [
    {
      name: "Integrada?",
      selector: "CODFILIAL",
      maxWidth: "70px",
      ignoreRowClick: true,
      center: true,
      cell: (row) =>
        row.INTEGRADA === "true" ? (
          <img src={verificado} />
        ) : (
          <img src={pedente} />
        ),
    },
    {
      name: "Envia?",
      selector: "CODFILIAL",
      maxWidth: "70px",
      center: true,
      cell: (row) =>
        row.INTEGRADA === "true" ? (
          <Switch
            inistate={row.ENVIOAUTOMATICO === "S" ? true : false}
            idempresa={row.IDEMPRESAENOTAS}
          />
        ) : (
          ""
        ),
    },
    {
      name: "Coligada",
      selector: "CODCOLIGADA",
      maxWidth: "70px",
      sortable: true,
    },
    {
      name: "Nome coligada",
      selector: "COLIGADA",
      sortable: true,
    },
    {
      name: "Filial",
      selector: "CODFILIAL",
      maxWidth: "70px",
      sortable: true,
    },
    {
      name: "Nome Filial",
      selector: "FILIAL",
      sortable: true,
    },
    {
      name: "Certificado",
      selector: "CERTVENCTO",
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Ações",
      cell: (row) => (
        <>
          <button
            onClick={() =>
              Swal.fire({
                icon: "question",
                title: "O que você vai Integrar?",
                text: "Para que haja uma integração de forma correta, favor selecione o tipo de integração que você deseja fazer!",
                showDenyButton: true,
                confirmButtonText: "NFSe",
                denyButtonText: `NFe`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleValidacaoClicked(
                    row.CODMUNICIPIOIBGE,
                    row.NOMEMUNICIPIO,
                    row.CODCOLIGADA,
                    row.CODFILIAL,
                    row.FILIAL,
                    row.INTEGRADA,
                    row.IDEMPRESAENOTAS
                  );
                } else if (result.isDenied) {
                  integarFilialNfe(
                    row.CODCOLIGADA,
                    row.CODFILIAL,
                    row.FILIAL,
                    row.INTEGRADA,
                    row.IDEMPRESAENOTAS
                  );
                }
              })
            }
            // onClick={() =>
            //   handleValidacaoClicked(
            //     row.CODMUNICIPIOIBGE,
            //     row.NOMEMUNICIPIO,
            //     row.CODCOLIGADA,
            //     row.CODFILIAL,
            //     row.FILIAL,
            //     row.INTEGRADA,
            //     row.IDEMPRESAENOTAS
            //   )
            // }
            style={{ marginRight: "5px" }}
            class="btn btn-primary"
          >
            {row.INTEGRADA === "true" ? "Atualizar" : "Integrar"}
          </button>
          {row.INTEGRADA === "true" ? (
            <button
              // onClick={() => enviarCertificado(row.FILIAL, row.IDEMPRESAENOTAS)}
              onClick={() => 
                Swal.fire({
                  icon: "question",
                  title: "O que você vai Integrar?",
                  text: "Para que haja uma integração de forma correta, favor selecione o tipo de integração que você deseja fazer!",
                  showDenyButton: true,
                  confirmButtonText: "Certificado NFSe",
                  denyButtonText: `Certificado NFe`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    enviarCertificado(row.FILIAL, row.IDEMPRESAENOTAS)
                  } else if (result.isDenied) {
                    enviarCertificadoNfe(row.FILIAL, row.IDEMPRESAENOTAS)
                  }
                })
              }
              
              class="btn btn-success"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              Certificado
            </button>
          ) : (
            <button
              onClick={() => alert(row.IDEMPRESAENOTAS)}
              class="btn btn-success"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              disabled
            >
              Certificado
            </button>
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "260px",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      <Modal
        isOpen={modalcertificado}
        toggle={abrircertificado}
        className="perfil-modal"
        centered={true}
      >
        <ModalHeader toggle={abrircertificado}>
          Envio de certificado digital - NFSe
        </ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => onSubmitCerificado(e)}>
            <span style={{ fontSize: "22px" }}>{nomefilial}</span>
            <div class="form-group"></div>
            <div class="form-group">
              <label>Arquivo</label>
              <br />
              <input
                type="file"
                name="arquivo"
                class="form-control input-shadow"
                onChange={(e) => {
                  setArquivo(e.currentTarget.files[0]);
                }}
                required
              />
            </div>
            <div class="form-group">
              <label>Senha</label>
              <br />
              <input
                type="password"
                name="senha"
                class="form-control input-shadow"
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </div>
            <br />
            <div class="form-group">
              <input
                type="hidden"
                // type="text"
                name="empresaId"
                value={idfilial}
                onChange={(e) => setIdfilial(e.target.value)}
              />
            </div>
            <button type="submit" class="btn btn-light btn-block">
              Enviar certificado
            </button>
            <br />
          </form>
        </ModalBody>
      </Modal>

      {/* Modal Certificado NFe */}
      <Modal
        isOpen={modalcertificadonfe}
        toggle={abrircertificadoNfe}
        className="perfil-modal"
        centered={true}
      >
        <ModalHeader toggle={abrircertificadoNfe}>
          Envio de certificado digital - NFe
        </ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => onSubmitCerificadoNfe(e)}>
            <span style={{ fontSize: "22px" }}>{nomefilial}</span>
            <div class="form-group"></div>
            <div class="form-group">
              <label>Arquivo</label>
              <br />
              <input
                type="file"
                name="arquivo"
                class="form-control input-shadow"
                onChange={(e) => {
                  setArquivo(e.currentTarget.files[0]);
                }}
                required
              />
            </div>
            <div class="form-group">
              <label>Senha</label>
              <br />
              <input
                type="password"
                name="senha"
                class="form-control input-shadow"
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </div>
            <br />
            <div class="form-group">
              <input
                type="hidden"
                // type="text"
                name="empresaId"
                value={idfilial}
                onChange={(e) => setIdfilial(e.target.value)}
              />
            </div>
            <button type="submit" class="btn btn-light btn-block">
              Enviar certificado
            </button>
            <br />
          </form>
        </ModalBody>
      </Modal>

      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Card>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={props.dados}
            noHeader
            defaultSortFieldId={1}
            paginationPerPage={15}
            sortIcon={<SortIcon />}
            defaultSortAsc={false}
            progressPending={props.load}
            progressComponent={<LoaderComponent />}
            pagination
            highlightOnHover
            selectableRows
          />
        </DataTableExtensions>
      </Card>
    </>
  );
}
