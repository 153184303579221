import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Tabela from "../../components/Tabela/TabelaMovimentoNfe";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import "react-widgets/styles.css";
import Icon, { FontAwesome, Feather, AntDesign } from "react-web-vector-icons";
import Contexto from "../../components/Contexto/Contexto";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


export default function Movimento() {
  const history = useHistory();
  const classes = useStyles();
  const [qtdmovimentos, setQtdmovimentos] = useState(0);
  const [dadostabela, setDadostabela] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);
  const [isload, setIsload] = useState(false);
  const [itensnfe, setitensnfe] = useState([]);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Notas Rejeitadas</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);
        // Carrego o Movimento
        const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };
        fetch(`${api}/movimentosnfe`, requestOptions)
        .then(response => response.json())
        .then(
          result => { 
            console.log(result);
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              setDadostabela([]);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count)
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
        });
      }
    }
  }, []);

  function CarregarMovimento() {
    const coligada = localStorage.getItem("@integrador-app/codcoligada");
    if (coligada === null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Notas Rejeitadas</span>',
        html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    } else {
      setIsloadtabela(true);
      // Carrego o Movimento
      const params = {
        "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
        "codfilial": localStorage.getItem("@integrador-app/codfilial"),
        "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
        "dataini": localStorage.getItem("@integrador-app/dtini"),
        "datafim": localStorage.getItem("@integrador-app/dtfim")
      }
      console.log(params);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };
      fetch(`${api}/movimentosnfe`, requestOptions)
      .then(response => response.json())
      .then(
        result => { 
          if (result.status_code == "400") {
            setQtdmovimentos(0);
            setDadostabela([]);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          } else {
            setDadostabela(result.dados);
            setQtdmovimentos(result.record_count);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          }
      });
    }
  }

  function EnviaTodasNota(id){
    setIsload(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "ci_session=e4fjubi9icfeqilf97l28gm5s66hu1ra");

    var raw = JSON.stringify({
      "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
      "codfilial": localStorage.getItem("@integrador-app/codfilial"),
      "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
      "idmov": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(
      `${api}/movimentosnfeid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        // Busco os Itens do movimento
        var itensHeaders = new Headers();
        itensHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "idmov": id
        });

        var requestOptions = {
          method: "POST",
          headers: itensHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `${api}/itenssnfeid`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);
            const _itens = [];
            for (let i = 0; i < result.dados.length; i++) {
              // console.log(result.dados[i]);
              var obj = {
                cfop: result.dados[i].CFOP,
                codigo: result.dados[i].CODIGOPRD,
                descricao: result.dados[i].NOMEFANTASIA,
                ncm: result.dados[i].NCM,
                codigoBeneficioFiscal: result.dados[i].CODIGOBENEFICIOFISCAL,
                quantidade: parseInt(result.dados[i].QUANTIDADE),
                unidadeMedida: result.dados[i].CODUND,
                valorUnitario: parseFloat(result.dados[i].PRECOUNITARIO),
                frete: 0.0,
                impostos: {
                  icms: {
                    situacaoTributaria: result.dados[i].ICMSSITUACAOTRIB,
                    origem: 0,
                  },
                  pis: {
                    situacaoTributaria: "99",
                  },
                  cofins: {
                    situacaoTributaria: "99",
                  },
                },
              };
              _itens.push(obj);
            }
            setitensnfe(_itens);
           })
          .catch((error) => console.log("error", error));

        // Monto a Nota Fiscal NFe
        var nota = {
          id: String(result.dados[0].IdMovimento),
          ambienteEmissao: "Producao",
          naturezaOperacao: "Venda",
          tipoOperacao: "Saida",
          finalidade: "Normal",
          consumidorFinal: true,
          enviarPorEmail: true,
          cliente: {
            tipoPessoa: result.dados[0].PessoaFisJur,
            indicadorContribuinteICMS: result.dados[0].TomadorContribuinte,
            nome: result.dados[0].TomadorRazaoSocial,
            email: result.dados[0].TomadorContatoEmail,
            telefone: result.dados[0].TomadorContatoTelefone,
            cpfCnpj: result.dados[0].TomadorIdentificacaoTomadorCpfCnpj,
            endereco: {
              uf: result.dados[0].TomadorEnderecoUf,
              cidade: result.dados[0].TomadorEnderecoCidade,
              logradouro: result.dados[0].TomadorEnderecoEndereco,
              numero: result.dados[0].TomadorEnderecoNumero,
              complemento: result.dados[0].TomadorEnderecoComplemento,
              bairro: result.dados[0].TomadorEnderecoBairro,
              cep: result.dados[0].TomadorEnderecoCEP,
            },
          },
          itens: itensnfe,
          informacoesAdicionais: result.dados[0].InformacoesAdicionais,
        };

        if (itensnfe.length > 1) {
          //console.log(nota);
          //setIsload(true);
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append(
            "Authorization",
            "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
          );
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify(nota);

          //console.log(nota);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `https://api.enotasgw.com.br/v2/empresas/${localStorage.getItem("@integrador-app/idempresa")}/nf-e`,
            requestOptions
          )
            .then((response) => {
              if (!response.ok) {
                return response.json().then((result) => {
                  if (result.length === 0) {
                    // Swal.fire({
                    //   title:
                    //     '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    //   html: '<p style="font-size: 16px;">Nota Fiscal enviada para a pefeitura, consulta a mesma para a verificação do Status na prefeitura.</p>',
                    //   icon: "success",
                    //   confirmButtonText: "Sair",
                    // });
                  } else {
                    // Swal.fire({
                    //   title:
                    //     '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    //   html:
                    //     '<ul style="font-size: 16px;">' +
                    //     result.map((error) => {
                    //       return (
                    //         '<li style="list-style-type: none;">' +
                    //         "Código: " +
                    //         error.codigo +
                    //         " - Erro: " +
                    //         error.mensagem +
                    //         "</li>"
                    //       );
                    //     }) +
                    //     "</ul>",
                    //   icon: "error",
                    //   confirmButtonText: "Sair",
                    // });
                  }
                  setIsload(false);
                });
              } else {
                setIsload(false);
                // Swal.fire({
                //   title:
                //     '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                //   html: '<p style="font-size: 16px;">Nota Fiscal enviada para a SEFAZ, consulte a mesma para a verificação do Status na SEFAZ.</p>',
                //   icon: "success",
                //   confirmButtonText: "Sair",
                // });
              }
            })
            .catch((error) => {
              setIsload(false);
              // Swal.fire({
              //   title:
              //     '<span style="font-size: 22px;">Envio de nota fiscal</span>',
              //   html: '<ul style="font-size: 16px;">Ocorreu um erro no momento do envio.</ul>',
              //   icon: "error",
              //   confirmButtonText: "Sair",
              // });
            });
        } else {
          // alert("Nota sem itens, tente novamente.");
          console.log(`Não tem itens no movimento `)
        }
        setIsload(false);
      })
      .catch((error) => {
        console.log("error", error)
        setIsload(false);
      });
  }

  function EnviarTodasNotas(){
    const movimento = dadostabela;
    // console.log(movimento[0].IDMOV);
    for (const envio of movimento) {
      EnviaTodasNota(envio.IDMOV);
    }
  }

  function ConsultaNota(id) {
    setIsload(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v2/empresas/${localStorage.getItem("@integrador-app/idempresa")}/nf-e/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((result) => {
        setIsload(false);
        // console.log(result);

        var MsgText = "";
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${result.id}</strong><br>`;
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${result.status}</strong><br>`;
        if (result.status !== "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${result.motivoStatus}</strong><br>`;
        }
        if (result.status === "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Chave de acesso: <strong>${result.chaveAcesso}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${result.protocolo.numero}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              result.dataAutorizacao
            ).format("DD/MM/YYYY")}</strong><br>`;
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            result.dataEmissao
          ).format("MM/YYYY")}</strong><br>`;

        const icone =
          result.status === "Negada"
            ? "error"
            : result.status === "Autorizada"
            ? "success"
            : "warning";
          
        if (result.status === "Autorizada") {
          var authHeaders = new Headers();
          authHeaders.append("Content-Type", "application/json");
          authHeaders.append(
            "Cookie",
            "ci_session=s1pgeqs2nifofho03ha1grpadv0ei5cm"
          );

          var corpo = JSON.stringify({
            codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
            idmov: id,
            status: "A",
            chaveacesso: result.chaveAcesso,
            xmlnfse: result.linkDownloadXml,
            numprotocolo: result.protocolo.numero,
            dtautoriza: moment(new Date()).format("YYYY-MM-DD"),
          });

          //console.log('Link do XML: '+json.linkDownloadXML);

          var requestParams = {
            method: "POST",
            headers: authHeaders,
            body: corpo,
            redirect: "follow",
          };

          fetch(
            `${api}/atualizastatusnfe`,
            requestParams
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
        }

        // Swal.fire({
        //   title:
        //     '<span style="font-size: 22px;">Consulta de nota fiscal</span>',
        //   html: MsgText,
        //   icon: icone,
        //   confirmButtonText: "Sair",
        // });
      })
      .catch((error) => {
        setIsload(false);
        // Swal.fire({
        //   title: '<span style="font-size: 22px;">Consulta de Nota</span>',
        //   html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${id} foi enviado pelo TOTVS</p>`,
        //   icon: "error",
        //   confirmButtonText: "Sair",
        // });
      });
  }

  function ConsultarTodasNotas(){
    const movimento = dadostabela;
      for (const consulta of movimento) {
        ConsultaNota(consulta.IDMOV);
      }
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Consulta de nota fiscal</span>',
        html: '<p>O Processo de consulta de todas as notas foram finalizadas, só ficaram na lista de pendentes de envio os movimentos que apresentarem problemas de dados. Recarregue a lista para atualizar os dados.</p>',
        icon: 'success',
        confirmButtonText: "Sair",
      });
  }

  return (
    <div id="wrapper">
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <Contexto />
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Notas Fiscais Pendentes { qtdmovimentos ? ' - Total: '+qtdmovimentos : '' }
                  <div class="card-action">
                    <div class="dropdown">
                      <a
                        href="javascript:void();"
                        class="dropdown-toggle dropdown-toggle-nocaret"
                        data-toggle="dropdown"
                      >
                        <i class="icon-options"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void();" onClick={CarregarMovimento}>
                        <FontAwesome
                            name="refresh"
                            color="#fff"
                            size={20}
                          />
                          {" "}Recarregar Movimento
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"  href="javascript:void();" onClick={EnviarTodasNotas}>
                          <FontAwesome
                            name="cloud-upload"
                            color="#fff"
                            size={20}
                          />
                          {" "}Enviar todas as Notas
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"  href="javascript:void();" onClick={ConsultarTodasNotas}>
                          <FontAwesome
                            name="history"
                            color="#fff"
                            size={20}
                          />
                          {" "}Consultar todas as notas
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabela dados={dadostabela} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
