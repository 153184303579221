import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Tabela from "../../components/Tabela/TabelaNotasEnviadas";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import moment from 'moment';
import "react-widgets/styles.css";
import Icon, { FontAwesome, Feather, AntDesign } from "react-web-vector-icons";
import Contexto from "../../components/Contexto/Contexto";
import { useApp } from '../../context/AppContext';

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

export default function Movimentos() {
  const history = useHistory();
  // const { user } = useApp();
  const [qtdmovimentos, setQtdmovimentos] = useState(0);
  const [dadostabela, setDadostabela] = useState([]);
  const [dadosprefeitura, setDadosprefeitura] = useState([]);
  const [dadostotfat, setDadostotfat] = useState([]);
  const [dadostotfatserv, setDadostotfatserv] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Notas Autorizadas</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

        var reqOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
          .then(response => response.json())
          .then(result => {
            setDadosprefeitura(result);
            // console.log(result);
          })
          .catch(error => console.log('error', error));

        // Carrego o Movimento
        const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "status": "A",
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };
        fetch(`${api}/notasfiscaisporstatus`, requestOptions)
        .then(response => response.json())
        .then(
          result => { 
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              setDadostabela([]);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count)
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
        });

        /* Totaliza o Total Faturado por Serviço */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestServicosTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalfaturadoporservico`, requestServicosTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotfatserv(result.dados);
          })
          .catch(error => console.log('error', error));
        
        /* Totaliza o Total Faturado */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalfaturado`, requestTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotfat(result.dados);
          })
          .catch(error => console.log('error', error));
      }
    }
  }, []);

  function CancelaNota(id) {
    const prefeitura_dados = JSON.parse(dadosprefeitura);
    
    if (dadosprefeitura !== null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Cancelamento de Notas</span>',
        html: '<p style="font-size: 16px;">A prefeitura suporta cancelamento de notas.</p>',
        icon: "success",
        confirmButtonText: "Sair",
      });
    } else {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Cancelamento de Notas</span>',
        html: '<p style="font-size: 16px;">Não foi possivel carregar os dados da prefeitura.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    }
  }

  function CarregarMovimento() {
    const coligada = localStorage.getItem("@integrador-app/codcoligada");
    if (coligada === null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Notas Autorizadas</span>',
        html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    } else {
      setIsloadtabela(true);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

      var reqOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
        .then(response => response.json())
        .then(result => {
          setDadosprefeitura(result);
          // console.log(result);
        })
        .catch(error => console.log('error', error));
        
      // Carrego o Movimento
      const params = {
        "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
        "codfilial": localStorage.getItem("@integrador-app/codfilial"),
        "status": "A",
        "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
        "dataini": localStorage.getItem("@integrador-app/dtini"),
        "datafim": localStorage.getItem("@integrador-app/dtfim")
      }
      console.log(params);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };
      fetch(`${api}/notasfiscaisporstatus`, requestOptions)
      .then(response => response.json())
      .then(
        result => { 
          if (result.status_code == "400") {
            setQtdmovimentos(0);
            setDadostabela([]);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          } else {
            setDadostabela(result.dados);
            setQtdmovimentos(result.record_count);
            localStorage.setItem('@integrador-app/qtdmov', result.record_count);
            setIsloadtabela(false);
          }
      });

      /* Totaliza o Total Faturado por Serviço */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

      const requestServicosTotal = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };

      fetch(`${api}/totalfaturadoporservico`, requestServicosTotal)
      .then(response => response.json())
      .then(
        result => { 
          setDadostotfatserv(result.dados);
        })
        .catch(error => console.log('error', error));

      /* Totaliza o Total Faturado */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

      const requestTotal = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };

      fetch(`${api}/totalfaturado`, requestTotal)
      .then(response => response.json())
      .then(
        result => { 
          setDadostotfat(result.dados);
        })
        .catch(error => console.log('error', error));
    }
  }

  return (
    <div id="wrapper">
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <Contexto />
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Informações de Faturamento
                </div>
                <div class="row" style={{ margin: '15px' }}>
                  <div class="col-md-6">
                    <h5>Permite cancelamento? 
                    
                      <h4>{dadosprefeitura.suportaCancelamento ? 
                          <FontAwesome
                            name='check'
                            color='#1ab883'
                            size={30}
                          />: 
                          <FontAwesome
                            name='remove'
                            color='#e35252'
                            size={30}
                          />
                          }</h4>
                    </h5>
                    <h5>Total Faturado no Período</h5>
                    <p>
                      {dadostotfat.map(function(item, i){
                        return(
                          <h4>{item.TotalServicoFaturado}</h4>
                        );
                      })}
                    </p><br/>
                    <h5>Total Faturado por Serviço</h5>
                    { dadostotfatserv.length > 0 ? (
                        dadostotfatserv.map(function(item, i){
                          return(
                            <li key={i}>{item.Servico} - {item.ValorFaturado}</li>
                          );
                        }
                        )
                      ) : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Notas Fiscais Autorizadas { qtdmovimentos ? ' - Total: '+qtdmovimentos : '' }
                  <div class="card-action">
                    <div class="dropdown">
                      <a
                        href="javascript:void();"
                        class="dropdown-toggle dropdown-toggle-nocaret"
                        data-toggle="dropdown"
                      >
                        <i class="icon-options"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void();" onClick={CarregarMovimento}>
                          Recarregar Notas
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabela dados={dadostabela} dadosPref={dadosprefeitura} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
