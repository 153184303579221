import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import logo from "../../assets/images/icon-application-w.png";
import { Form, Input } from "reactstrap";
import Swal from "sweetalert2";
import GoogleLogin from "react-google-login";
import "./login.css";
import $ from "jquery";
import { useApp } from '../../context/AppContext';

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

export default function Login() {
  const history = useHistory();
  const [values, setValues] = useState([]);
  const [check, setCheck] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const { setUser } = useApp();

  const responseGoogle = (response) => {
    // console.log(response.profileObj.email);
    const stringExemplo = response.profileObj.email;
    const usuario = stringExemplo.split("@");
    setIsloading(true);
    // console.log(usuario[0]);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "ci_session=8aao9981h3d96nb8t0jn8c8r2agjq3ia");

    var raw = JSON.stringify({ "usuario": usuario[0].trim(), "senha": "" });

    var requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${api}/login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        setIsloading(false);
        if (result.status_code === 200) {
          console.log(result.dados.STATUS);
          if (result.dados[0].STATUS === 0) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Acesso do usuário bloqueado no sistema.",
            });
          } else {
            localStorage.setItem("@integrador-app/codusuario", result.dados[0].CODUSUARIO);
            localStorage.setItem("@integrador-app/nome", result.dados[0].NOME);
            localStorage.setItem("@integrador-app/email", result.dados[0].EMAIL);
            localStorage.setItem("@integrador-app/imgusuario", response.profileObj.imageUrl);

            setUser({
              logado: true,
              codusuario: result.dados[0].CODUSUARIO,
              nome: result.dados[0].NOME,
              email: result.dados[0].EMAIL,
              avatar : response.profileObj.imageUrl
            });

            // window.location.href = "/painel";
            history.push('/painel');
          }
        } else {
          Swal.fire({
            icon: "error",
            title: '<span style="font-size: 22px;">Falha no login</span>',
            text: 'Não foi possivel logar com essa conta, verifique sua conta no TOTVS.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setIsloading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel efetuar o login no momento, tente novamente mais tarde.",
        });
      });
  };

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario !== null) {
      history.push("/painel");
      // window.location.href = "/painel";
    }
  }, []);

  function handleChange(e) {
    setCheck(e.target.value);
  }

  function onChange(ev) {
    const { name, value } = ev.target;
    setValues({ ...values, [name]: value });
  }

  function onLogin(values) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "ci_session=8aao9981h3d96nb8t0jn8c8r2agjq3ia");

    var raw = JSON.stringify({ "usuario": values.username.trim(), "senha": "" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${api}/login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        
        if (result.status_code === 200) {
          if (result.dados[0].STATUS === 0) {
            setIsloading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Acesso do usuário bloqueado no sistema.",
            });
          } else {
            localStorage.setItem("@integrador-app/codusuario", result.dados[0].CODUSUARIO);
            localStorage.setItem("@integrador-app/nome", result.dados[0].NOME);
            localStorage.setItem("@integrador-app/email", result.dados[0].EMAIL);
            localStorage.setItem("@integrador-app/imgusuario", 'https://media.istockphoto.com/vectors/profile-placeholder-image-gray-silhouette-no-photo-vector-id1016744004?k=20&m=1016744004&s=612x612&w=0&h=Z4W8y-2T0W-mQM-Sxt41CGS16bByUo4efOIJuyNBHgI=');
            setUser({
              logado: true,
              codusuario: result.dados[0].CODUSUARIO,
              nome: result.dados[0].NOME,
              email: result.dados[0].EMAIL,
              avatar : 'https://media.istockphoto.com/vectors/profile-placeholder-image-gray-silhouette-no-photo-vector-id1016744004?k=20&m=1016744004&s=612x612&w=0&h=Z4W8y-2T0W-mQM-Sxt41CGS16bByUo4efOIJuyNBHgI='
            });
            // window.location.href = "/painel";
            history.push('/painel');
          }
        } else {
          Swal.fire({
            icon: "error",
            title: '<span style="font-size: 22px;">Falha no login</span>',
            text: 'Não foi possivel logar com essa conta, verifique sua conta no TOTVS.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setIsloading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel efetuar o login no momento, tente novamente mais tarde.",
        });
      });
  }

  async function onSubmit(ev) {
    ev.preventDefault();
    setIsloading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + btoa(`${values.username}:${values.password}`));

    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${tbc}/dataserver/autenticaacesso`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloading(false);
        if (result.status == 401) {
          Swal.fire({
            icon: "error",
            title: "Login!",
            text: result.data,
          });
        } else {
          onLogin(values);
        }
      })
      .catch(error => {
        setIsloading(false);
        Swal.fire({
          icon: "error",
          title: "Eror!",
          text: error.data,
        });
      });
  }

  return (
    <div id="wrapper">
      <div class="loader-wrapper">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="card card-authentication1 mx-auto my-5">
        <div class="card-body">
          <div class="card-content p-2">
            <div class="text-center">
              <img src={logo} alt="" width="150px" />
            </div>
            <div class="card-title text-uppercase text-center py-3">
              Integrador Fiscal
            </div>
            <Form onSubmit={onSubmit}>
              <div class="form-group">
                <label>Usuário</label>
                <input
                  id="username"
                  name="username"
                  class="form-control input-shadow"
                  onChange={onChange}
                  required
                >
                </input>
              </div>
              <div class="form-group">
                <label>Senha</label>
                <input
                  id="password"
                  name="password"
                  class="form-control input-shadow"
                  type="password"
                  onChange={onChange}
                  required
                >
                </input>
              </div>
              <button type="submit" class="btn btn-light btn-block">
                {isloading ? <PulseLoader color={'#fff'} loading={true} size={5} /> : 'Entrar' }
              </button>
            </Form>
            {/* <br />
            <hr />
            <GoogleLogin
              clientId="714937342706-qou1kjapvno9qkekslujlttr1a0c4fij.apps.googleusercontent.com"
              buttonText="Login com conta coorporativa"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              className="btn btn-block"
            /> */}
          </div>
        </div>
        <div class="card-footer text-center py-3">
          <Link href=""> © Todos direitos reservados 2021</Link>
        </div>
      </div>
    </div>
  );
}
