import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Load from "./components/Load/Load";
import Home from "./views/Home/Home";
import Login from "./views/Login/Login";
import Filiais from "./views/Filiais/Filiais";
import Movimentos from "./views/Movimentos/Movimentos";
import Autorizadas from "./views/Notasfiscais/Autorizadas";
import Canceladas from "./views/Notasfiscais/Canceladas";
import Rejeitadas from "./views/Notasfiscais/Rejeitadas";
import Agendamento from "./views/Agendamento/Agendamento";
import MovimentoNfe from "./views/Nfe/Movimento";
import Boletos from "./views/Modulos/financeiro/Boletos";
import SemRota from "./views/NotFound/NotFound";
import './App.css';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },5000)
  }, [])
  
  /*
  const  SemRota = () =>{
    return (
      <h1>Sem rota definida.</h1>
    );
  }
  */
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
        {
          loading ?
            <Load />
          :
            <Home />
        }
        </Route>
        <Route exact path="/entrar" component={Login} />
        <Route exact path="/painel" component={Home} />
        <Route exact path="/filiais" component={Filiais} />
        <Route exact path="/movimento" component={Movimentos} />
        <Route exact path="/nfseautorizadas" component={Autorizadas} />
        <Route exact path="/nfsecanceladas" component={Canceladas} />
        <Route exact path="/nfserejeitadas" component={Rejeitadas} />
        <Route exact path="/gerenciamento" component={Agendamento} />
        <Route exact path="/movimentonfe" component={MovimentoNfe} />
        <Route exact path="/financeiro/BoletosView" component={Boletos} />
        <Route path="*" component={SemRota} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
