import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import Swal from 'sweetalert2'
import Switch from 'react-switch'
import { useApp } from '../../context/AppContext'

const api = process.env.REACT_APP_HOST

const initialvalue = {
  codcoligada: '',
  codfilial: '',
  codtpmov: '',
  dtini: '',
  dtfim: '',
}

export default function Navbar() {
  const history = useHistory()
  const { user, setUser } = useApp()
  const [values, setValues] = useState(initialvalue)
  const [coligadafilial, setColigadafilial] = useState()
  const [cnpj, setCnpj] = useState('')
  const [coligada, setColigada] = useState(0)
  const [valuesColigadas, setValuesColigadas] = useState([])
  const [isLoadColigadas, setisLoadColigadas] = useState(false)
  const [valuesFiliais, setValuesFiliais] = useState([])
  const [isLoadFiliais, setisLoadFiliais] = useState(false)
  const [valuesTipoCursos, setValuesTipoCursos] = useState([])
  const [isLoadTipoCursos, setisLoadTipoCursos] = useState(false)
  const [modalcontexto, setModalcontexto] = useState(false)
  const abrirContexto = () => setModalcontexto(!modalcontexto)

  const [checked, setChecked] = useState(false)
  const [dadosmatriz, setDadosMatriz] = useState({
    codcoligada: 0,
    codfilial: 0,
  })

  function handleChange(event) {
    setChecked(event.target.checked)
    console.log('Log', event.target.checked)

    if (event.target.checked === true) {
      console.log('Buscando dados da matriz....')
      setDadosMatriz({
        codcoligada: coligada,
        codfilial: 1,
      })
      fetch(`${api}/getfiliais?codcoligada=${coligada}&codfilial=1`)
        .then((response) => response.json())
        .then((result) => {
          //localStorage.setItem('@integrador-app/filialcnpj', result.dados[0].CGC);
          //localStorage.setItem('@integrador-app/codcoligada', values.codcoligada);
          //localStorage.setItem('@integrador-app/codfilial', values.codfilial);
          localStorage.setItem('@integrador-app/usacnpjmatriz', 'S')
          localStorage.setItem(
            '@integrador-app/codmunicipmatriz',
            result.dados[0].CODMUNICIPIOIBGE
          )
          localStorage.setItem(
            '@integrador-app/idempresamatriz',
            result.dados[0].IDEMPRESAENOTAS
          )

          //history.push("/movimento");
        })
    } else {
      localStorage.removeItem('@integrador-app/usacnpjmatriz')
      localStorage.removeItem('@integrador-app/codmunicipmatriz')
      localStorage.removeItem('@integrador-app/idempresamatriz')
    }
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })

    if (ev.target.name === 'codcoligada') {
      setisLoadFiliais(true)
      fetch(`${api}/getfiliais?codcoligada=${ev.target.value}`)
        .then((response) => response.json())
        .then((result) => {
          console.log('target')
          setValuesFiliais(result.dados)
          setColigada(ev.target.value)
          setisLoadFiliais(false)
        })
    }

    if (ev.target.name === 'codfilial') {
      setisLoadTipoCursos(true)
      fetch(
        `${api}/gettipocurso?codcoligada=${coligada}&codfilial=${
          ev.target.value
        }&codusuario=${localStorage.getItem('@integrador-app/codusuario')}`
      )
        .then((response) => response.json())
        .then((result) => {
          setisLoadTipoCursos(false)
          if (result.record_count > 0) {
            setValuesTipoCursos(result.dados)
          } else {
            setValuesTipoCursos([])
          }
        })
        .catch((error) => {
          console.log('error', error)
          setValuesTipoCursos([])
          setisLoadTipoCursos(false)
        })
    }
  }

  useEffect(() => {
    setisLoadColigadas(true)
    fetch(
      `${api}/getcoligadasusuario/?codusuario=${localStorage.getItem(
        '@integrador-app/codusuario'
      )}`
      // `${api}/getcoligadas`
    )
      .then((response) => response.json())
      .then((result) => {
        // setValuesColigadas(result.dados)
        setisLoadColigadas(false)

        const perfilUnidades = result.dados.find(
          (usuario) =>
            usuario.CODPERFIL === 'S008' ||
            usuario.CODPERFIL === 'S010' ||
            usuario.CODPERFIL === 'F005'
        )
        // const perfilUnidades = false
        const perfilFaturamento = result.dados.find(
          (usuario) =>
            usuario.CODPERFIL === 'S006' || usuario.CODPERFIL === 'DBA'
        )
        // const perfilFaturamento = true
        const role = perfilUnidades
          ? 'Unidades'
          : perfilFaturamento
          ? 'Faturamento'
          : 'SemPermissao'
        setUser({
          logado: user.logado,
          codusuario: user.codusuario,
          nome: user.nome,
          email: user.email,
          avatar: user.avatar,
          role: role,
        })
        const rsdados = perfilUnidades
          ? setValuesColigadas(
              result.dados.filter(
                (usuario) =>
                  usuario.CODPERFIL === 'S008' ||
                  usuario.CODPERFIL === 'S010' ||
                  usuario.CODPERFIL === 'F005'
              )
            )
          : perfilFaturamento
          ? setValuesColigadas(
              result.dados.filter(
                (usuario) =>
                  usuario.CODPERFIL === 'S006' || usuario.CODPERFIL === 'DBA'
              )
              // result.dados
            )
          : Swal.fire({
              title:
                '<span style="font-size: 22px;">Permissão de Acesso</span>',
              html: '<p style="font-size: 16px;">O usuário não possue perimissão de acesso, favor verificar com o adiministrador do sistema.</p>',
              icon: 'error',
              confirmButtonText: 'Sair',
            }).then((result) => {
              if (result.isConfirmed) {
                cleanStorage()
              } else {
                cleanStorage()
              }
            })

        console.log(rsdados)
        // console.log(perfilUnidades ? 'Redirect perfil unidades' : perfilFaturamento ? 'Redirect perfil faturamento' : 'Não tem permissão');
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  function onSubmitContext(e) {
    e.preventDefault()
    fetch(
      `${api}/getfiliais?codcoligada=${values.codcoligada}&codfilial=${values.codfilial}`
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setColigadafilial(
          result.dados[0].CODNOMEFANTASIACOLIGADA +
            ' / ' +
            result.dados[0].CODNOMEFANTASIA
        )
        localStorage.setItem(
          '@integrador-app/coligadafilial',
          result.dados[0].CODNOMEFANTASIACOLIGADA +
            ' / ' +
            result.dados[0].CODNOMEFANTASIA
        )
        localStorage.setItem('@integrador-app/filialcnpj', result.dados[0].CGC)
        localStorage.setItem('@integrador-app/codcoligada', values.codcoligada)
        localStorage.setItem('@integrador-app/codfilial', values.codfilial)
        localStorage.setItem(
          '@integrador-app/codtipocurso',
          values.codtipocurso
        )
        localStorage.setItem('@integrador-app/codtpmov', values.codtpmov)
        localStorage.setItem('@integrador-app/dtini', values.dtini)
        localStorage.setItem('@integrador-app/dtfim', values.dtfim)
        localStorage.setItem(
          '@integrador-app/codmunicip',
          result.dados[0].CODMUNICIPIOIBGE
        )
        localStorage.setItem(
          '@integrador-app/idempresa',
          result.dados[0].IDEMPRESAENOTAS
        )
        localStorage.setItem(
          '@integrador-app/integrada',
          result.dados[0].INTEGRADA
        )

        //history.push("/movimento");
      })
    abrirContexto()
  }

  function cleanStorage() {
    localStorage.removeItem('@integrador-app/codusuario')
    localStorage.removeItem('@integrador-app/imgusuario')
    localStorage.removeItem('@integrador-app/nome')
    localStorage.removeItem('@integrador-app/email')
    localStorage.removeItem('@integrador-app/coligadafilial')
    localStorage.removeItem('@integrador-app/filialcnpj')
    localStorage.removeItem('@integrador-app/codcoligada')
    localStorage.removeItem('@integrador-app/codfilial')
    localStorage.removeItem('@integrador-app/codtpmov')
    localStorage.removeItem('@integrador-app/dtini')
    localStorage.removeItem('@integrador-app/dtfim')
    localStorage.removeItem('@integrador-app/qtdmov')
    localStorage.removeItem('@integrador-app/codmunicip')
    localStorage.removeItem('@integrador-app/idempresa')
    localStorage.removeItem('@integrador-app/integrada')
    setUser({
      logado: '',
      codusuario: '',
      nome: '',
      email: '',
      avatar: '',
      role: '',
    })
    history.push('/entrar')
  }

  function logout() {
    Swal.fire({
      title: 'Sair do sistema',
      text: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, por favor!',
    }).then((result) => {
      if (result.isConfirmed) {
        cleanStorage()
      }
    })
  }

  return (
    <div>
      <Modal
        isOpen={modalcontexto}
        toggle={abrirContexto}
        className="perfil-modal"
        centered={true}
      >
        <ModalHeader toggle={abrirContexto}>
          Assistente de configuração do contexto
        </ModalHeader>
        <ModalBody>
          <form onSubmit={onSubmitContext}>
            <div class="form-group">
              <label>Coligada</label>
              <select
                id="codcoligada"
                name="codcoligada"
                class="form-control input-shadow"
                onChange={onChange}
                required
              >
                <option>Selecione a Coligada</option>
                {isLoadColigadas ? (
                  <option>Carregando...</option>
                ) : (
                  valuesColigadas.map((itensColigadas) => (
                    <option
                      key={itensColigadas.CODCOLIGADA}
                      value={itensColigadas.CODCOLIGADA}
                    >
                      {itensColigadas.CODNOMEFANTASIA}
                    </option>
                  ))
                )}
              </select>
            </div>
            <div class="form-group">
              <label>Filial</label>
              <select
                id="codfilial"
                name="codfilial"
                class="form-control input-shadow"
                onChange={onChange}
                required
              >
                <option>Selecione a Filial</option>
                {isLoadFiliais ? (
                  <option>Carregando...</option>
                ) : (
                  valuesFiliais.map((itensFiliais) => (
                    <option
                      key={itensFiliais.CODFILIAL}
                      value={itensFiliais.CODFILIAL}
                    >
                      {itensFiliais.CODNOMEFANTASIA}
                    </option>
                  ))
                )}
              </select>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="usarcnpjmatriz"
                onChange={handleChange}
              />
              <label class="form-check-label" for="checkUsarCnpjMatriz">
                Usar CNPJ da Matriz para envio de notas?
              </label>
            </div>
            <span>
              <b>
                {checked ? 'Matriz: ' + dadosmatriz.codcoligada + '.1' : null}
              </b>
            </span>
            <hr />
            <div class="form-group">
              <label>Nível de Ensino</label>
              <select
                id="codtipocurso"
                name="codtipocurso"
                class="form-control input-shadow"
                onChange={onChange}
                required
              >
                <option>Selecione o nível de ensino</option>
                {isLoadTipoCursos ? (
                  <option>Carregando...</option>
                ) : valuesTipoCursos.length > 0 ? (
                  valuesTipoCursos.map((itensTpCursos) => (
                    <option
                      key={itensTpCursos.CODTIPOCURSO}
                      value={itensTpCursos.CODTIPOCURSO}
                    >
                      {itensTpCursos.NOME}
                    </option>
                  ))
                ) : (
                  <option disabled>***Nível de ensino não liberado***</option>
                )}
              </select>
            </div>
            <div class="form-group">
              <label>Tipo de Movimento</label>
              <select
                id="codtpmov"
                name="codtpmov"
                class="form-control input-shadow"
                onChange={onChange}
                required
              >
                <option>Selecione o Tipo de Movimento</option>
                <option value="2.2.01">
                  2.2.01 - NFS - Educação Básica e Continuada
                </option>
                <option value="2.2.02">
                  2.2.02 - NF - Venda Direta - SEFAZ
                </option>
                {/* <option value="2.2.07">
                  2.2.07 - NF - Venda para Entrega Futura
                </option> */}
                <option value="2.2.11">2.2.11 - NF - Serviços A+</option>
              </select>
            </div>
            <div class="form-group">
              <label>Data inicial</label>
              <br />
              <input
                type="date"
                name="dtini"
                class="form-control input-shadow"
                time={false}
                onChange={onChange}
                required
              />
            </div>
            <div class="form-group">
              <label>Data Final</label>
              <br />
              <input
                type="date"
                name="dtfim"
                class="form-control input-shadow"
                time={false}
                onChange={onChange}
                required
              />
            </div>
            <br />
            <button type="submit" class="btn btn-light btn-block">
              Confirmar
            </button>
            <br />
          </form>
        </ModalBody>
      </Modal>
      <nav class="navbar navbar-expand fixed-top">
        <ul class="navbar-nav mr-auto align-items-center">
          <li class="nav-item">
            <a class="nav-link toggle-menu" href="javascript:void();">
              <i class="icon-menu menu-icon"></i>
            </a>
          </li>
          <li class="nav-item">
            <div class="search-bar">
              <a href="javascript:void();">
                {localStorage.getItem('@integrador-app/coligadafilial')}
              </a>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav align-items-center right-nav-link">
          <li
            class="nav-item dropdown-lg"
            onClick={abrirContexto}
            style={{ cursor: 'pointer' }}
          >
            <i class="icon-settings mr-2"></i>
          </li>
          <li class="nav-item">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-nocaret"
              data-toggle="dropdown"
              href="#"
            >
              <span class="user-profile">
                <img
                  src={localStorage.getItem('@integrador-app/imgusuario')}
                  class="img-circle"
                  alt="avatar"
                />
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item user-details">
                <a href="javaScript:void();">
                  <div class="media">
                    <div class="avatar">
                      <img
                        class="align-self-start mr-3"
                        src={localStorage.getItem('@integrador-app/imgusuario')}
                        alt="user avatar"
                      />
                    </div>
                    <div class="media-body">
                      <h6 class="mt-2 user-title">
                        {localStorage.getItem('@integrador-app/nome')}
                      </h6>
                      <p class="user-subtitle">
                        {localStorage.getItem('@integrador-app/email')}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li
                class="dropdown-item"
                onClick={abrirContexto}
                style={{ cursor: 'pointer' }}
              >
                <i class="icon-settings mr-2"></i> Contexto do Sistema
              </li>
              <li class="dropdown-divider"></li>
              <li
                class="dropdown-item"
                onClick={logout}
                style={{ cursor: 'pointer' }}
              >
                <i class="icon-power mr-2"></i> Logout
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}
