import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext({});

export const AppProvider = (props) => {
    const [modoescuro, setModoescuro] = useState(false);
    const [msg, setMsg] = useState("Bem-vindo");

    const [user, setUser] = useState({
        logado: false,
        codusuario: "",
        nome: "rrgg",
        avatar: "",
        email: "",
        role: "",
        token: "",
        pemissoes : {

        }
    });

    const [contexto, setContexto] = useState({
        logado: false,
        codusuario: "",
        nome: "rrgg",
        avatar: "",
        email: "",
        role: "",
        token: "",
        pemissoes : {
            
        }
    });

    return(
        <AppContext.Provider value={{ modoescuro, setModoescuro , user, setUser, msg, setMsg, contexto, setContexto }}>
            {props.children}
        </AppContext.Provider>
    );
};

export const useApp = () => useContext(AppContext);