import React from 'react';
//import './notfound.css';

function NotFound() {
  return (
    <div className='container'>
         
        <div class='c'>
            <div class='_404'>404</div>
            <hr/>
            <div class='_1'>THE PAGE</div>
            <div class='_2'>A PÁGINA QUE VOCÊ ESTÁ PROCURANDO NÃO EXISTE</div>
            <br/>
            <a class='btn-not-found' href='#'>Voltar a página anterior</a>
        </div>
    </div>
  )
}

export default NotFound;