import React, { Component } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

class SwitchButton extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: this.props.inistate, isload: false };
    this.handleChange = this.handleChange.bind(this);
  }
  classes = () => this.useStyles();
  handleChange(checked) {
    this.setState({ checked });
    if (checked == false){
      // alert('Caso desmarque essa opção o envio automático do movimeto para esta filial será desativdo e o envio será de forma manual. '+checked)
      Swal.fire({
        title: "Atenção",
        html: '<p style="font-size: 16px;">Caso desmarque essa opção o envio automático do movimento para esta filial será desativado e o envio será de forma manual. Deseja continuar?</p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ checked: false, isload : true });
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Cookie", "ci_session=kisl74rdl8tilgd219otvok406vetuc7");

          var raw = JSON.stringify({
            "empresaId": this.props.idempresa,
            "ativo": "N"
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://www.suporteinspira.com.br/api/integrador/index.php/api/envioautomatico", requestOptions)
            .then(response => {
              return response.json();
            })
            .then((json) => {
              console.log(json);
              this.setState({ isload : false });
              Swal.fire({
                title:
                  '<span style="font-size: 22px;">Envio Automático</span>',
                html: '<p style="font-size: 16px;">O envio automático do movimento para esta filial foi desativado.</p>',
                icon: "success",
                confirmButtonText: "Sair",
              });
            })
            .catch(error => console.log('error', error));
        } else {
          this.setState({ checked: true });
        }
      });
    } else {
      this.setState({ checked: true });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=kisl74rdl8tilgd219otvok406vetuc7");

      var raw = JSON.stringify({
        "empresaId": this.props.idempresa,
        "ativo": "S"
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://www.suporteinspira.com.br/api/integrador/index.php/api/envioautomatico", requestOptions)
        .then(response => {
          return response.json()
        })
        .then((json) => {
          Swal.fire({
            title:'<span style="font-size: 22px;">Envio Automático</span>',
            html: '<p style="font-size: 16px;">O envio automático do movimento para esta filial foi ativado.</p>',
            icon: "success",
            confirmButtonText: "Sair",
          });
          console.log(json);
          this.setState({ isload : false });
        })
        .catch(error => console.log('error', error));
    }
  }

  render() {
    return (
        <>
          {this.state.isload ? (
              <Backdrop className={this.classes.backdrop} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              ""
            )
          }
          <Switch 
            onChange={this.handleChange} 
            checked={this.state.checked} 
          />
        </>
    );
  }
}

export default SwitchButton;