import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import PulseLoader from "react-spinners/PulseLoader";
import Swal from "sweetalert2";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { FormatAlignJustifyTwoTone } from "@material-ui/icons";
import Icon, { FontAwesome, Feather, AntDesign } from "react-web-vector-icons";
import moment from "moment";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function TabelaMovimentoNfe(props) {
  const classes = useStyles();
  const [isload, setIsload] = useState(null);
  const [msg, setmsg] = useState([]);
  const [nota, setnota] = useState([]);
  const [itensnfe, setitensnfe] = useState([]);
  const [msgErrro, setmsgErrro] = useState("");

  useEffect(() => {}, []);

  function handleEnviaNota(id) {
    msg.pop();
    var notaHeaders = new Headers();
    notaHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
      codfilial: localStorage.getItem("@integrador-app/codfilial"),
      codtitmov: localStorage.getItem("@integrador-app/codtpmov"),
      idmov: id,
    });

    var requestOptions = {
      method: "POST",
      headers: notaHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${api}/movimentosnfeid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setIsload(true);
        let _id = String(result.dados[0].IdMovimento);
        let _tipoPessoa = result.dados[0].PessoaFisJur;
        let _indicadorContribuinteICMS = result.dados[0].TomadorContribuinte;
        let _nome = result.dados[0].TomadorRazaoSocial;
        let _email = result.dados[0].TomadorContatoEmail;
        let _telefone = result.dados[0].TomadorContatoTelefone;
        let _cpfCnpj = result.dados[0].TomadorIdentificacaoTomadorCpfCnpj;
        let _uf = result.dados[0].TomadorEnderecoUf;
        let _cidade = result.dados[0].TomadorEnderecoCidade;
        let _logradouro = result.dados[0].TomadorEnderecoEndereco;
        let _numero = result.dados[0].TomadorEnderecoNumero;
        let _complemento = result.dados[0].TomadorEnderecoComplemento;
        let _bairro = result.dados[0].TomadorEnderecoBairro;
        let _cep = result.dados[0].TomadorEnderecoCEP;
        let _informacoesAdicionais = result.dados[0].InformacoesAdicionais;

        // Busco os Itens do movimento
        var itensHeaders = new Headers();
        itensHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
          idmov: id,
        });

        var requestOptions = {
          method: "POST",
          headers: itensHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `${api}/itenssnfeid`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);
            const _itens = [];
            for (let i = 0; i < result.dados.length; i++) {
              // console.log(result.dados[i]);
              var obj = {
                cfop: result.dados[i].CFOP,
                codigo: result.dados[i].CODIGOPRD,
                descricao: result.dados[i].NOMEFANTASIA,
                ncm: result.dados[i].NCM,
                codigoBeneficioFiscal: result.dados[i].CODIGOBENEFICIOFISCAL,
                quantidade: parseInt(result.dados[i].QUANTIDADE),
                unidadeMedida: result.dados[i].CODUND,
                valorUnitario: parseFloat(result.dados[i].PRECOUNITARIO),
                frete: 0.0,
                impostos: {
                  icms: {
                    situacaoTributaria: result.dados[i].ICMSSITUACAOTRIB,
                    origem: 0,
                  },
                  pis: {
                    situacaoTributaria: "99",
                  },
                  cofins: {
                    situacaoTributaria: "99",
                  },
                },
              };
              // itens.push(result.dados[i]);
              _itens.push(obj);
            }
            setitensnfe(_itens);

            if (_itens.length > 0) {
              // console.log(`Tenho ${_itens.length} dados...`);
        
              // Monto a Nota Fiscal NFe
              var nota = {
                id: _id,
                ambienteEmissao: "Producao",
                naturezaOperacao: "Venda",
                tipoOperacao: "Saida",
                finalidade: "Normal",
                consumidorFinal: true,
                enviarPorEmail: false,
                cliente: {
                  tipoPessoa: _tipoPessoa,
                  indicadorContribuinteICMS: _indicadorContribuinteICMS,
                  nome: _nome,
                  email: _email,
                  telefone: _telefone,
                  cpfCnpj: _cpfCnpj,
                  endereco: {
                    uf: _uf,
                    cidade: _cidade,
                    logradouro: _logradouro,
                    numero: _numero,
                    complemento: _complemento,
                    bairro: _bairro,
                    cep: _cep,
                  },
                },
                itens: _itens,
                informacoesAdicionais: _informacoesAdicionais,
              };

              console.log(nota);

              if (itensnfe.length > 1) {
                
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append(
                  "Authorization",
                  "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
                );
                myHeaders.append("Content-Type", "application/json");
      
                var raw = JSON.stringify(nota);
      
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };
      
                fetch(
                  `https://api.enotasgw.com.br/v2/empresas/${localStorage.getItem("@integrador-app/idempresa")}/nf-e`,
                  requestOptions
                )
                  .then((response) => {
                    if (!response.ok) {
                      return response.json().then((result) => {
                        if (result.length === 0) {
                          Swal.fire({
                            title:
                              '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                            html: '<p style="font-size: 16px;">Nota Fiscal enviada para a pefeitura, consulta a mesma para a verificação do Status na prefeitura.</p>',
                            icon: "success",
                            confirmButtonText: "Sair",
                          });
                        } else {
                          Swal.fire({
                            title:
                              '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                            html:
                              '<ul style="font-size: 16px;">' +
                              result.map((error) => {
                                return (
                                  '<li style="list-style-type: none;">' +
                                  "Código: " +
                                  error.codigo +
                                  " - Erro: " +
                                  error.mensagem +
                                  "</li>"
                                );
                              }) +
                              "</ul>",
                            icon: "error",
                            confirmButtonText: "Sair",
                          });
                        }
                        setIsload(false);
                      });
                    } else {
                      setIsload(false);
                      Swal.fire({
                        title:
                          '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                        html: '<p style="font-size: 16px;">Nota Fiscal enviada para a SEFAZ, consulte a mesma para a verificação do Status na SEFAZ.</p>',
                        icon: "success",
                        confirmButtonText: "Sair",
                      });
                    }
                  })
                  .catch((error) => {
                    setIsload(false);
                    Swal.fire({
                      title:
                        '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                      html: '<ul style="font-size: 16px;">Ocorreu um erro no momento do envio.</ul>',
                      icon: "error",
                      confirmButtonText: "Sair",
                    });
                  });
              } else {
                setIsload(false);
                // alert("Nota sem itens, tente novamente.");
                Swal.fire({
                  title:
                    '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                  html: '<ul style="font-size: 16px;">Nota sem itens, realize a correção e tente novamente</ul>',
                  icon: "error",
                  confirmButtonText: "Sair",
                });
              }
            }
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
  }

  function handleConsultaNota(id) {
    setIsload(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v2/empresas/${localStorage.getItem("@integrador-app/idempresa")}/nf-e/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((result) => {
        setIsload(false);
        console.log(result);

        var MsgText = "";
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${result.id}</strong><br>`;
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${result.status}</strong><br>`;
        if (result.status !== "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${result.motivoStatus}</strong><br>`;
        }
        if (result.status === "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Chave de acesso: <strong>${result.chaveAcesso}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${result.protocolo.numero}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              result.dataAutorizacao
            ).format("DD/MM/YYYY")}</strong><br>`;
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            result.dataEmissao
          ).format("MM/YYYY")}</strong><br>`;

        const icone =
          result.status === "Negada"
            ? "error"
            : result.status === "Autorizada"
            ? "success"
            : "warning";
          
        if (result.status === "Autorizada") {
          var authHeaders = new Headers();
          authHeaders.append("Content-Type", "application/json");
          authHeaders.append(
            "Cookie",
            "ci_session=s1pgeqs2nifofho03ha1grpadv0ei5cm"
          );

          var corpo = JSON.stringify({
            codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
            idmov: id,
            status: "A",
            chaveacesso: result.chaveAcesso,
            xmlnfse: result.linkDownloadXml,
            numprotocolo: result.protocolo.numero,
            dtautoriza: moment(new Date()).format("YYYY-MM-DD"),
          });

          //console.log('Link do XML: '+json.linkDownloadXML);

          var requestParams = {
            method: "POST",
            headers: authHeaders,
            body: corpo,
            redirect: "follow",
          };

          fetch(
            `${api}/atualizastatusnfe`,
            requestParams
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
        }

        Swal.fire({
          title:
            '<span style="font-size: 22px;">Consulta de nota fiscal</span>',
          html: MsgText,
          icon: icone,
          confirmButtonText: "Sair",
        });
      })
      .catch((error) => {
        setIsload(false);
        Swal.fire({
          title: '<span style="font-size: 22px;">Consulta de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${id} foi enviado pelo TOTVS</p>`,
          icon: "error",
          confirmButtonText: "Sair",
        });
      });
  }

  const LoaderComponent = () => (
    <div
      style={{
        height: "150px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <PulseLoader color={"#1565C0"} loading={true} size={7} />
    </div>
  );

  const data = props.dados;

  const columns = [
    {
      name: "Ref",
      selector: "IDMOV",
      maxWidth: "70px",
      sortable: true,
    },
    {
      name: "Status",
      selector: "STATUS",
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Data Emissão",
      selector: "DataEmissao",
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: "Tipo Mov",
      selector: "CODTMV",
      maxWidth: "110px",
      sortable: true,
    },
    {
      name: "Valor (R$)",
      selector: "ValorNfe",
      sortable: true,
      maxWidth: "110px",
      right: true,
    },
    {
      name: "Tomador",
      selector: "TomadorRazaoSocial",
      sortable: true,
    },
    {
      name: "CPF/CNPJ",
      selector: "CGCCFO",
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: "Ações",
      cell: (row) => (
        <>
          <div
            onClick={() => handleEnviaNota(row.IDMOV)}
            style={{
              backgroundColor: "#088555",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: "center",
              borderRadius: 5,
              cursor: "pointer",
              marginRight: "5px",
              color: "#fff",
              fontSize: 14,
            }}
          >
            Enviar <FontAwesome name="cloud-upload" color="#fff" size={20} />
          </div>
          <div
            onClick={() => handleConsultaNota(row.IDMOV)}
            style={{
              backgroundColor: "#bd9a1e",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: "center",
              borderRadius: 5,
              cursor: "pointer",
              marginRight: "5px",
            }}
          >
            <FontAwesome
              // onClick={() => handleConsultaNota(row.IdMovimento)}
              name="refresh"
              color="#fff"
              size={20}
            />
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "260px",
    },
  ];

  const NoData = () => {
    return (
      <p
        style={{
          color: "#000",
          fontSize: 16,
          marginTop: 20,
          marginBottom: 70,
        }}
      >
        Não foram encotrados notas nesse período.
      </p>
    );
  };

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Card>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={props.dados}
            noDataComponent={<NoData />}
            noHeader
            defaultSortFieldId={1}
            paginationPerPage={15}
            sortIcon={<SortIcon />}
            defaultSortAsc={false}
            progressPending={props.load}
            progressComponent={<LoaderComponent />}
            pagination
            highlightOnHover
            selectableRows
          />
        </DataTableExtensions>
      </Card>
    </>
  );
}
