import React, { useEffect, useState } from "react";
import moment from 'moment';

export default function Contexto(props) {
  return (
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-header">Filtro do contexto</div>
          <div class="row" style={{ margin: "15px" }}>
            <div class="col-md-6">
              <h6>Tipo de Faturamento</h6>
              <p>{localStorage.getItem("@integrador-app/codtpmov")}</p>
              <h6>Período de Emissão</h6>
              <p>
                {moment(localStorage.getItem("@integrador-app/dtini")).format(
                  "DD/MM/YYYY"
                )}{" "}
                até{" "}
                {moment(localStorage.getItem("@integrador-app/dtfim")).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
