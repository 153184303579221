import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Cron from 'react-cron-generator';
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import "react-widgets/styles.css";

export default function Agendamento() {
  const history = useHistory();
  const [dadostabela, setDadostabela] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      // history.push("/entrar");
    } else {
      // setIsloadtabela(true);
      // fetch("https://www.suporteinspira.com.br/api/integrador/index.php/api/getfiliaisintegracao")
      // .then(response => response.json())
      // .then(result => {
      //   setDadostabela(result.dados);
      //   setIsloadtabela(false);
      // });
    }
  }, []);

  return (
    <div id="wrapper">
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Gerenciamento do Serviço
                </div>
                <div class="card-body">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-circle mr-2 text-white"></i>Pendente de
                      Faturamento
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-circle mr-2 text-light"></i>Faturado
                    </li>
                  </ul>
                  <Cron
                    onChange={(e)=> {setValue(e); console.log(e)}}
                    value={value}
                    showResultText={true}
                    showResultCron={true}
                  />  
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush table-borderless">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Photo</th>
                        <th>Product ID</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Shipping</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Iphone 5</td>
                        <td>
                          <img
                            src="https://via.placeholder.com/110x110"
                            class="product-img"
                            alt="product img"
                          />
                        </td>
                        <td>#9405822</td>
                        <td>$ 1250.00</td>
                        <td>03 Aug 2017</td>
                        <td>
                          <div
                            class="progress shadow"
                            style={{ height: "3px" }}
                          >
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style={{ width: "90%" }}
                            ></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>    
              </div>
            </div>
            
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
