import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import PulseLoader from "react-spinners/PulseLoader";
import Swal from "sweetalert2";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from "moment";
import "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesome } from "react-web-vector-icons";
import { useApp } from '../../context/AppContext';

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function TabelaMovimento(props) {
  const classes = useStyles();
  const { user } = useApp();
  const [isload, setIsload] = useState(null);

  useEffect(() => {
    console.log(props.dadosPref.suportaCancelamento);
  }, []);

  function handleCancelaNota(nota){
    if (props.dadosPref.suportaCancelamento) {
      Swal.fire({
        title: "Cancelamento da Nota "+nota+"?",
        text: "Você tem certeza que deseja cancelar a nota, caso sim a nota será cancelada de forma permanente na prefeitura. Deseja cancelar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo!",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsload(true);
          //console.log(dadosPrefeitura);
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

          var raw = "";

          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
            "@integrador-app/idempresa"
          )}/nfes/porIdExterno/${nota}`, requestOptions)
            .then((response) => {
              setIsload(false);
              if (response.status === 200) {
                
                // Troco o Status da Nota para Pendente
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var rawnotascanceladas = JSON.stringify({
                  "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
                  "idmov": nota,
                  "status": "C",
                  "numnfse": "",
                  "xmlnfse": null,
                  "obs": null,
                  "numprotocolo": "",
                  "dtautoriza": "2021-03-10"
                });

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: rawnotascanceladas,
                  redirect: 'follow'
                };

                fetch(`${api}/atualizastatusnfse`, requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    console.log(result)
                    if (result.status_code === 200){
                      Swal.fire({
                        title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
                        html: `<p align="center" style="font-size: 16px;">A nota foi cancelada com sucesso!!!</p>`,
                        icon: "success",
                        confirmButtonText: "Sair",
                      });
                    } else {
                      Swal.fire({
                        title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
                        html: `<p align="center" style="font-size: 16px;">A nota foi <b>cancelada</b> porém não foi possível mudar o status da naota.</p>`,
                        icon: "error",
                        confirmButtonText: "Sair",
                      });
                    }
                  })
                  .catch(error => console.log('error', error));

                  

              } else {
                //return response.json();
                Swal.fire({
                  title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
                  html: `<p align="center" style="font-size: 16px;">Não foi possível solicitar o cancelamento da NF-e, pois a mesma já se encontra com o status de <b>cancelada</b>.</p>`,
                  icon: "error",
                  confirmButtonText: "Sair",
                });
              }
            })
            .catch(error => {
              setIsload(false);
              console.log('error', error);
              Swal.fire({
                title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
                html: `<p align="center" style="font-size: 16px;">${error[0].mensagem}.</p>`,
                icon: "error",
                confirmButtonText: "Sair",
              });
            });
          
        }
      });
    } else {
      Swal.fire({
        title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
        html: `<p align="center" style="font-size: 16px;">Esta prefeitura não suporta o cancelamento de notas, favor entrar em contato com a mesma para realizar o procedimento.</p>`,
        icon: "error",
        confirmButtonText: "Sair",
      });
    }
    
  }

  function handleXML(id) {
    setIsload(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
        "@integrador-app/idempresa"
      )}/nfes/porIdExterno/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((json) => {
        console.log(json);
        var MsgText = "";
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${json.idExterno}</strong><br>`;
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${json.status}</strong><br>`;
        if (json.status !== "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${json.motivoStatus}</strong><br>`;
        }
        if (json.status === "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Número da nota: <strong>${json.numero}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${json.codigoVerificacao}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              json.dataAutorizacao
            ).format("DD/MM/YYYY")}</strong><br>`;
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            json.dataCompetenciaRps
          ).format("MM/YYYY")}</strong><br>`;

        const icone =
          json.status === "Negada"
            ? "error"
            : json.status === "Autorizada"
            ? "success"
            : "warning";

        if (json.status === "Autorizada") {
         console.log(json);
         window.open(json.linkDownloadXML, '_blank');
        }
      })
      .catch((error) => {
        Swal.fire({
          title: '<span style="font-size: 22px;">Consulta de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${id} foi enviado pelo TOTVS</p>`,
          icon: "error",
          confirmButtonText: "Sair",
        });
        // console.log('error', error)
      });
  }

  function handlePdf(id) {
    setIsload(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
        "@integrador-app/idempresa"
      )}/nfes/porIdExterno/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((json) => {
        console.log(json);
        var MsgText = "";
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Id. Externo: <strong>${json.idExterno}</strong><br>`;
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Status: <strong>${json.status}</strong><br>`;
        if (json.status !== "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Obs Status: <strong>${json.motivoStatus}</strong><br>`;
        }
        if (json.status === "Autorizada") {
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Número da nota: <strong>${json.numero}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Protocolo: <strong>${json.codigoVerificacao}</strong><br>`;
          MsgText =
            MsgText +
            `<p align="left" style="font-size: 16px;">Data da autorização: <strong>${moment(
              json.dataAutorizacao
            ).format("DD/MM/YYYY")}</strong><br>`;
        }
        MsgText =
          MsgText +
          `<p align="left" style="font-size: 16px;">Competência: <strong>${moment(
            json.dataCompetenciaRps
          ).format("MM/YYYY")}</strong><br>`;

        const icone =
          json.status === "Negada"
            ? "error"
            : json.status === "Autorizada"
            ? "success"
            : "warning";

        if (json.status === "Autorizada") {
         console.log(json);
         window.open(json.linkDownloadPDF, '_blank');
        }
      })
      .catch((error) => {
        Swal.fire({
          title: '<span style="font-size: 22px;">Consulta de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">A Nota fiscal não foi encontrada no eNotas. Por favor, verifique se o id ${id} foi enviado pelo TOTVS</p>`,
          icon: "error",
          confirmButtonText: "Sair",
        });
        // console.log('error', error)
      });
  }

  const LoaderComponent = () => (
    <div
      style={{
        height: "150px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <PulseLoader color={"#1565C0"} loading={true} size={7} />
    </div>
  );

  const data = props.dados;

  const columns = [
    {
      name: "Ref",
      selector: "IDMOV",
      maxWidth: "70px",
      sortable: true,
    },
    {
      name: "Status",
      selector: "STATUS",
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Movimento",
      selector: "NUMEROMOV",
      maxWidth: "120px",
      sortable: true,
    },
    {
      name: "Dt. Autorização",
      selector: "DATAAUTORIZACAO",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Valor (R$)",
      selector: "ValorServicos",
      sortable: true,
      maxWidth: "110px",
      right: true,
    },
    {
      name: "Tomador",
      selector: "NOME",
      sortable: true,
    },
    {
      name: "CPF/CNPJ",
      selector: "CGCCFO",
      sortable: true,
    },
    {
      name: "Serviço",
      selector: "SERVICO",
      sortable: true,
    },
    {
      name: "Protocolo",
      selector: "PROTOCOLO",
      sortable: true,
    },
    {
      name: "Ações",
      cell: (row) => (
        <>
          <div
            onClick={() => handleXML(row.IDMOV)}
            style={{
              backgroundColor: "#088555",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: "center",
              borderRadius: 5,
              cursor: "pointer",
              marginRight: "5px",
              color: "#fff",
              fontSize: 14,
            }}
          >
            <FontAwesome
              name="file-code-o"
              color="#fff"
              size={16}
            />
          </div>
          <div
            onClick={() => handlePdf(row.IDMOV)}
            style={{
              backgroundColor: "#bd9a1e",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: "center",
              borderRadius: 5,
              cursor: "pointer",
              marginRight: "5px",
            }}
          >
            <FontAwesome
              name="file-pdf-o"
              color="#fff"
              size={16}
            />
          </div>
          { user.role === "Faturamento" ?
              props.dadosPref.suportaCancelamento ?
                <div
                  onClick={() => handleCancelaNota(row.IDMOV)}
                  style={{
                    backgroundColor: "#fc0317",
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                    borderRadius: 5,
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  <FontAwesome
                    name="ban"
                    color="#fff"
                    size={16}
                  />
                </div>
              : '' 
            : null
          }
        </>
      ),
    },
  ];

  const NoData = () => {
    return (
      <p
        style={{
          color: "#000",
          fontSize: 16,
          marginTop: 20,
          marginBottom: 70,
        }}
      >
        Não foram encotrados notas nesse período.
      </p>
    );
  };

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Card>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={props.dados}
            noDataComponent={<NoData />}
            noHeader
            defaultSortFieldId={1}
            paginationPerPage={15}
            sortIcon={<SortIcon />}
            defaultSortAsc={false}
            progressPending={props.load}
            progressComponent={<LoaderComponent />}
            pagination
            highlightOnHover
            selectableRows
          />
        </DataTableExtensions>
      </Card>
    </>
  );
}
