import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import "./home.css";
import logo from "../../assets/images/icon-application-w.png";
import { useApp } from '../../context/AppContext';

export default function Home() {
  const history = useHistory();
  const { user, setUser } = useApp();

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
    }
  }, []);

  return (
    <div id="wrapper">
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <div class="card mt-3">
            <div class="card-content">
            </div>
          </div>
          <div 
            class="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={logo} class="logo-icon" alt="" /> 
            {/* <span>{user.nome}</span> */}
            {console.log(user)}
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
