import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field } from 'react-final-form';
import moment from "moment";
import Wizard from './Wizard';
import PulseLoader from "react-spinners/PulseLoader";
import logo from "../../assets/images/icon-application-w.png";
import success from "../../assets/images/success.png";
import error from "../../assets/images/error.png";
import './menu.css';
import { useApp } from '../../context/AppContext';

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const initialvalue = {
  codtpmov: "",
  dtini: "",
  dtfim: ""
};

export default function Menu(props) {
  const history = useHistory();
  const { user } = useApp();
  const [values, setValues] = useState(initialvalue);
  const [isloading, setIsloading] = useState(false);
  const [returnproccess, setReturnProccess] = useState({});
  const [modalGeraMovimentos, setModalGeraMovimentos] = useState(false);
  const abrirFormMovmentos = () => {
    setReturnProccess([]);
    setModalGeraMovimentos(!modalGeraMovimentos);
  };

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const onSubmit = async values => {
    await sleep(300)
    // window.alert(JSON.stringify(values, 0, 2))
    setIsloading(true);


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWxlc3NhbmRyby5nb25jYWx2ZXM6MTI4MDEyMDY=");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "ProcessServerName": "EduNFEData",
      "strXmlParams": values
    });

    var requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${tbc}/processos/geracaoregeracaomov`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic YWxlc3NhbmRyby5nb25jYWx2ZXM6MTI4MDEyMDY=");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "codsentenca": "FL.JOB.00001",
          "codcoligada": 0,
          "codsistema": "G",
          "param": `JOBID=${result.idJob}`
        });

        var requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(`${tbc}/consultas/realizarconsultasql`, requestOptions)
          .then(response => response.json())
          .then(result => {
            setIsloading(false);
            setReturnProccess(result.Resultado)
            console.log(result)
          })
          .catch(error => {
            setIsloading(false);
            console.log('error', error)
          });
      })
      .catch(error => {
        setIsloading(false);
        console.log('error', error)
      });
  }

  const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span>{error}</span> : null
      }
    />
  )

  const required = value => (value ? undefined : 'Required')

  function onChange(ev) {
    const { name, value } = ev.target;
    setValues({ ...values, [name]: value });
  }

  function onSubmitContext(e) {
    e.preventDefault();
    // abrirFormMovmentos();
  }

  return (
    <div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
      <div class="brand-logo">
        <Link to="/painel">
          <img src={logo} class="logo-icon" alt="" />
        </Link>
      </div>
      <div className="side-scroll">
      <ul class="sidebar-menu do-nicescrol">
        <li class="sidebar-header">NAVEGAÇÃO DO SISTEMA</li>
        <li>
          <Link to="/painel">
            <i class="zmdi zmdi-view-dashboard"></i> <span>Dashboard</span>
          </Link>
        </li>
        <li class={user.role === "Faturamento" ? "sidebar-header" : "hide"}>INTEGRAÇÃO</li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/filiais">
            <i class="zmdi zmdi-grid"></i><span>Integrar Filial ao Sistema</span>
          </Link>
        </li>
        <hr />
        <li class="sidebar-header">NOTAS DE SERVIÇO - NFSe</li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <a href="#" onClick={abrirFormMovmentos}>
            <i class="zmdi zmdi-collection-add"></i> <span>Geração de Movimentos</span>
          </a>
        </li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/movimento">
            <i class="zmdi zmdi-calendar-check"></i> <span>Pendente de Faturamento</span>
          </Link>
        </li>
        <li>
          <Link to="/nfseautorizadas">
            <i class="zmdi zmdi-book-image"></i> <span>Autorizadas</span>
          </Link>
        </li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/nfsecanceladas">
            <i class="zmdi zmdi-block-alt"></i> <span>Canceladas</span>
          </Link>
        </li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/nfserejeitadas">
            <i class="zmdi zmdi-alert-circle-o"></i> <span>Rejeitadas</span>
          </Link>
        </li>
        <hr class={user.role === "Faturamento" ? "" : "hide"} />
        <li class={user.role === "Faturamento" ? "sidebar-header" : "hide"}>NOTAS PRODUTOS - NFe</li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/movimentonfe">
            <i class="zmdi zmdi-calendar-check"></i> <span>Pendentes</span>
          </Link>
        </li>
        <li class={user.role === "Faturamento" ? "" : "hide"}>
          <Link to="/movimentonfe">
            <i class="zmdi zmdi-alert-circle-o"></i> <span>Rejeitadas</span>
          </Link>
        </li>
        <li class="sidebar-header">CENTRAL</li>
        {/* <li>
            <a href="#">
              <i class="zmdi zmdi-coffee text-danger"></i> <span>FAQ</span>
            </a>
          </li> */}
        <li>
          <a href="javaScript:void();">
            <i class="zmdi zmdi-chart-donut text-success"></i>{" "}
            <span>Versão 1.1.7</span>
          </a>
        </li>
      </ul>
      </div>

      <Modal
        isOpen={modalGeraMovimentos}
        toggle={abrirFormMovmentos}
        className="perfil-modal"
        size="lg"
        centered={true}
      >
        <ModalHeader toggle={abrirFormMovmentos}>
          Geração de Movimentos NFS-e
        </ModalHeader>
        <ModalBody>
          <Wizard
            initialValues={
              {
                "codColigada": Number(localStorage.getItem("@integrador-app/codcoligada")),
                "codFilial": Number(localStorage.getItem("@integrador-app/codfilial")),
                "codTipoCurso": Number(localStorage.getItem("@integrador-app/codtipocurso")),
                "codUsuarioLogado": localStorage.getItem("@integrador-app/codusuario"),
                "gerarMovimentoValorZero": false,
                "permiteRegerarMov": false,
                "permiteMovValorLiquidoZero": false,
                "desconsideraBolsaCredito": false,
              }
            }
            onSubmit={onSubmit}
          >
            <Wizard.Page>
              <div>
                <label>Parâmetros de movimentos</label>
                <hr />
                <div class="form-group">
                  <label>Tipo de Movimento</label>
                  <Field component="select"
                    id="codtpmov"
                    name="codtpmov"
                    class="form-control input-shadow"
                    validate={required}
                  >
                    <option>Selecione o Tipo de Movimento</option>
                    <option value="2.2.01">
                      2.2.01 - NFS - Educação Básica e Continuada
                    </option>
                  </Field>
                </div>
                <hr />
                <div class="form-group">
                  <label>Data de emissão do movimento</label>
                  <br />
                  <span>
                    <Field
                      name="origemDataEmissao"
                      component="input"
                      type="radio"
                      value="DataEmissaoLan"
                      validate={required}
                    />{' '}
                    Data de emissão do lançamento
                  </span><br />
                  <span>
                    <Field
                      name="origemDataEmissao"
                      component="input"
                      type="radio"
                      value="DataVencimentoLan"
                      validate={required}
                    />{' '}
                    Data de vencimento do lançamento
                  </span><br />
                  <span>
                    <Field
                      name="origemDataEmissao"
                      component="input"
                      type="radio"
                      value="DataBaixaLan"
                      validate={required}
                    />{' '}
                    Data da baixa do lançamento (somente p/ lanctos baixados)
                  </span><br />
                  <span>
                    <Field
                      name="origemDataEmissao"
                      component="input"
                      type="radio"
                      value="DataCompetenciaLan"
                      validate={required}
                    />{' '}
                    Data de competência do lançamento
                  </span><br />
                  <span>
                    <Field
                      name="origemDataEmissao"
                      component="input"
                      type="radio"
                      value="DataAtualSistema"
                      validate={required}
                    />{' '}
                    Data atual do sistema
                  </span>
                </div>
                <hr />
                <div class="form-group">
                  <Field name="permiteRegerarMov" component="input" type="checkbox" />{' '}Permitir regeração de movimentos
                </div>
                <div class="form-group">
                  <Field name="permiteMovValorLiquidoZero" component="input" type="checkbox" />{' '}Gerar movimentos para boletos de valor líquido igual a zero
                </div>
                <div class="form-group">
                  <Field name="desconsideraBolsaCredito" component="input" type="checkbox" />{' '}Desconsiderar bolsas de crédito para valores de desconto
                </div>
              </div>
            </Wizard.Page>
            <Wizard.Page>
              <label>Especifique os dados p/ seleção de boletos</label>
              <hr />
              <label>Data de Emissão</label>
              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                    <label>Data inicial</label>
                    <br />
                    <Field
                      component="input"
                      type="date"
                      name="dtiniemissao"
                      class="form-control input-shadow"
                      time={false}
                      required
                    />
                  </div>
                  <div class="col-6">
                    <label>Data Final</label>
                    <br />
                    <Field
                      component="input"
                      type="date"
                      name="dtfimemissao"
                      class="form-control input-shadow"
                      time={false}
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <label>Data de Vencimento</label>
              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                    <label>Data inicial</label>
                    <br />
                    <Field
                      component="input"
                      type="date"
                      name="dtinivencto"
                      class="form-control input-shadow"
                      time={false}
                    />
                  </div>
                  <div class="col-6">
                    <label>Data Final</label>
                    <br />
                    <Field
                      component="input"
                      type="date"
                      name="dtfimvencto"
                      class="form-control input-shadow"
                      time={false}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-group">
                <label>Filtro por boleto</label>
                <Field component="select"
                  id="codfiltro"
                  name="codfiltro"
                  class="form-control input-shadow"
                  validate={required}
                >
                  <option>Selecione o Filtro</option>
                  <option value="1">
                    Boletos sem movimento
                  </option>
                  {/* <option value="2">
                    Por Id. do boleto
                  </option> */}
                </Field>
              </div>
              <hr />
              <div class="form-group">
                <label>Status do lançamento</label>
                <Field component="select"
                  id="statuslan"
                  name="statuslan"
                  class="form-control input-shadow"
                  validate={required}
                >
                  <option>Selecione o Status</option>
                  <option value="Todos">
                    Todos
                  </option>
                  <option value="Aberto">
                    Em aberto
                  </option>
                  <option value="Baixado">
                    Baixado
                  </option>
                  <option value="BxAcordo">
                    Baixado por acordo
                  </option>
                </Field>
              </div>
              <hr />
            </Wizard.Page>
            <Wizard.Page>
              {isloading ? (
                <>
                  {returnproccess.STATUS !== undefined ? (<></>) : (
                  <>
                    <label>Execução do Processo</label>
                    <hr />
                  </>)}
                </>
              ) : (
                <>
                  {returnproccess.STATUS !== undefined ? (<></>) : (
                    <>
                      <label>Especifique os parâmetro do filtro</label>
                      <hr />
                      <div class="form-group">
                        <div class="row">
                          <div class="col-6">
                            <label>Data inicial</label>
                            <br />
                            <Field
                              component="input"
                              type="date"
                              name="dtinifiltro"
                              class="form-control input-shadow"
                              time={false}
                              required
                            />
                          </div>
                          <div class="col-6">
                            <label>Data Final</label>
                            <br />
                            <Field
                              component="input"
                              type="date"
                              name="dtfimfiltro"
                              class="form-control input-shadow"
                              time={false}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Ano Letívo</label>
                        <br />
                        <Field
                          component="input"
                          type="Text"
                          name="codperlet"
                          class="form-control input-shadow"
                        />
                      </div>
                      <hr />
                    </>
                  )}
                </>
              )}
              <div class="row">
                <div class="col-12">
                  {isloading ? (
                    <>
                      <div
                        class="row"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '20px',
                          marginBottom : '20px'
                        }}
                      >
                        <span
                          style={{
                            marginRight: '5px',
                            fontWeight: '600',
                            color: '#f2f542'
                          }}
                        >O processo está sendo executado, aguarde </span>
                        <PulseLoader color={'#f2f542'} loading={true} size={5} />
                      </div>
                    </>
                  ) :
                      returnproccess.STATUS !== undefined ?
                      (
                        <div class="row">
                          <div
                            class="col-12"
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            {returnproccess.STATUS === "Erro" ? (
                              <>
                                <img
                                  src={error}
                                  alt=""
                                  style={{
                                    height: '32px',
                                    with: '32px',
                                    marginRight: '10px'
                                  }}
                                />
                                <span
                                  style={{
                                    color: '#9c0310',
                                    fontSize: '16px',
                                    fontWeight: '600'
                                  }}
                                >Ocorreu um erro na execução do processo.</span>
                              </>
                            ) : (
                              <>
                                <img
                                  src={success}
                                  alt=""
                                  style={{
                                    height: '32px',
                                    with: '32px',
                                    marginRight: '10px'
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600'
                                  }}
                                >Execução do processo foi concluída com sucesso!</span>
                              </>
                            )}
                          </div>
                          <div class="col-4">
                            <label>Solicitado em:</label>
                            <br />
                            <span>{moment(returnproccess.DATAINIEXEC).format("DD/MM/YYYY HH:MM:SS")}</span>
                          </div>
                          <div class="col-4">
                            <label>Iniciado em:</label>
                            <br />
                            <span>{moment(returnproccess.DATAINIEXEC1).format("DD/MM/YYYY HH:MM:SS")}</span>
                          </div>
                          <div class="col-4">
                            <label>Finalizado em:</label>
                            <br />
                            <span>{moment(returnproccess.DATAFIMEXEC).format("DD/MM/YYYY HH:MM:SS")}</span>
                          </div>
                          <div class="col-12">
                            <textarea
                              id="log"
                              name="log"
                              readOnly={true}
                              value={returnproccess.LOG ? returnproccess.LOG : returnproccess.MENSAGEMSTATUS}
                              class="form-control"
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                height: '400px',
                                fontSize: '14px'
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            class="row"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '20px',
                              marginBottom : '20px'
                            }}
                          >
                            <span
                              style={{
                                marginRight: '5px',
                                fontWeight: '600',
                                color: '#fff'
                              }}
                            >O processo foi concluído com sucesso, movimento gerado!! </span>
                          </div>
                        </>
                      ) }
                </div>
                <br />
                <br />
              </div>

            </Wizard.Page>
          </Wizard>
        </ModalBody>
      </Modal>
    </div>
  );
}