import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import PulseLoader from "react-spinners/PulseLoader";
import Swal from "sweetalert2";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { FormatAlignJustifyTwoTone } from "@material-ui/icons";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function TabelaNotasRejeitadas(props) {
  const classes = useStyles();
  const [isload, setIsload] = useState(null);

  useEffect(() => {
    // function handleValidacaoClicked(id){
    //   alert(' was clicked!');
    // }
  }, []);

  const LoaderComponent = () => (
    <div
      style={{
        height: "150px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <PulseLoader color={"#1565C0"} loading={true} size={7} />
    </div>
  );

  const data = props.dados;

  const columns = [
    {
      name: "Ref",
      selector: "IDMOV",
      maxWidth: "70px",
      sortable: true,
    },
    {
      name: "Status",
      selector: "STATUS",
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Movimento",
      selector: "NUMEROMOV",
      maxWidth: "120px",
      sortable: true,
    },
    {
      name: "Valor (R$)",
      selector: "ValorServicos",
      sortable: true,
      maxWidth: "110px",
      right: true,
    },
    {
      name: "Tomador",
      selector: "NOME",
      sortable: true,
    },
    {
      name: "CPF/CNPJ",
      selector: "CGCCFO",
      sortable: true,
    },
    {
      name: "Serviço",
      selector: "SERVICO",
      sortable: true,
    },
  ];

  const NoData = () => {
    return (
      <p
        style={{
          color: "#000",
          fontSize: 16,
          marginTop: 20,
          marginBottom: 70,
        }}
      >
        Não foram encotrados notas nesse período.
      </p>
    );
  };

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Card>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={props.dados}
            noDataComponent={<NoData />}
            noHeader
            defaultSortFieldId={1}
            paginationPerPage={15}
            sortIcon={<SortIcon />}
            defaultSortAsc={false}
            progressPending={props.load}
            progressComponent={<LoaderComponent />}
            pagination
            highlightOnHover
            selectableRows
          />
        </DataTableExtensions>
      </Card>
    </>
  );
}
