import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import DatePicker from "react-widgets/DatePicker";
import Tabela from "../../components/Tabela/Tabela";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import "./filiais.css";
import "react-widgets/styles.css";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

export default function Filiais() {
  const history = useHistory();
  const [dadostabela, setDadostabela] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      setIsloadtabela(true);
      fetch(`${api}/getfiliaisintegracao`)
      .then(response => response.json())
      .then(result => {
        setDadostabela(result.dados);
        setIsloadtabela(false);
      });
    }
  }, []);

  return (
    <div id="wrapper">
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Filiais para Integração
                </div>
                <Tabela  dados={dadostabela} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
